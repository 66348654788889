import * as actionTypes from '../types';

const initialState = {
  open: false,
  step: 0,
  etherscanUrl: '',
  txHashes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROGRESS_MODAL_TOGGLE:
      return {
        ...state,
        open: action.data.open,
        step: 0,
        etherscanUrl: '',
        txHashes: []
      };

    case actionTypes.PROGRESS_UPDATE_STATUS:
      return {
        ...state,
        open: action.data.open,
        step: action.data.step,
        etherscanUrl: action.data.etherscanUrl,
        txHashes: action.data.txHashes || []
      };

    default:
      return state;
  }
};
