import React, { Component } from 'react';

import Footer from '../../components/Footer';

class PrivacyPolicy extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="post-wrap p-large" style={{ marginTop: '80px' }}>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 post-content">
                <h2>Privacy Policy</h2>
                <p>
                  We exceed the clients' expectations the website doesn't have the theme i was going for we try your
                  eye, but can you change everything? so you are lucky to even be doing this for us so make it original,
                  and you can get my logo from facebook what is a hamburger menu. Make it pop I want you to take it to
                  the next level can you turn it around in photoshop so we can see more of the front, and we need more
                  images of groups of people having non-specific types of fun, nor we are a non-profit organization, yet
                  I like it, but can the snow look a little warmer, nor I want you to take it to the next level.
                </p>
                <p>
                  Anyway, you are the designer, you know what to do i know you've made thirty iterations but can we go
                  back to the first one that was the best version but anyway, you are the designer, you know what to do.
                  We don't need a contract, do we this looks perfect.
                </p>
                <p>
                  Prairie dogging low-hanging fruit. Show pony staff engagement, so curate. Game-plan hard stop I have
                  zero cycles for this. Put your feelers out we just need to put these last issues to bed. Make sure to
                  include in your wheelhouse ladder up / ladder back to the strategy digital literacy but obviously. Not
                  enough bandwidth highlights for open door policy gain traction. High touch client those options are{' '}
                </p>
                <img src="/img/post/image-post.jpg" alt="" />
                <p>
                  Expectations the website doesn't have the theme i was going for we try your eye, but can you change
                  everything? so you are lucky to even be doing this for us so make it original, and you can get my logo
                  from facebook what is a hamburger menu. Make it pop I want you to take it to the next level can you
                  turn it around in photoshop so we can see more of the front, and we need more images of groups of
                  people having non-specific types of fun, nor we are a non-profit organization, yet I like it, but can
                  the snow look a little warmer, nor I want you to take it to the next level.
                </p>
                <q>
                  I have zero cycles for this proceduralize, so baseline the procedure and samepage your department
                  drink from the firehose we need to button up our approach or back to the drawing-board.
                </q>
                <p>
                  We exceed the clients' expectations the website doesn't have the theme i was going for we try your
                  eye, but can you change everything? so you are lucky to even be doing this for us so make it original,
                  and you can get my logo from facebook what is a hamburger menu. Make it pop I want you to take it to
                  the next level can you turn it around in photoshop so we can see more of the front, and we need more
                  images of groups of people having non-specific types of fun, nor we are a non-profit organization, yet
                  I like it, but can the snow look a little warmer, nor I want you to take it to the next level.
                </p>
                <p>
                  Anyway, you are the designer, you know what to do i know you've made thirty iterations but can we go
                  back to the first one that was the best version but anyway, you are the designer, you know what to do.
                  We don't need a contract, do we this looks perfect.
                </p>
                <p>
                  Prairie dogging low-hanging fruit. Show pony staff engagement, so curate. Game-plan hard stop I have
                  zero cycles for this. Put your feelers out we just need to put these last issues to bed. Make sure to
                  include in your wheelhouse ladder up / ladder back to the strategy digital literacy but obviously. Not
                  enough bandwidth highlights for open door policy gain traction. High touch client those options are.{' '}
                </p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
