import * as actionTypes from '../types';

const initialState = {
  investAddress: '',
  investToken: '',
  investAmount: 0,
  investedAt: '',
  txHash: null,

  totalAmount: 0,
  totalCount: 0,

  fetchRegistryError: null,
  registerBroError: null,
  fetchBroTotalError: null,

  registerBroLoading: false,
  fetchRegistryLoading: false,
  fetchBroTotalLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTER_BRO:
      return {
        ...state,
        registerBroLoading: true
      };

    case actionTypes.REGISTER_BRO_SUCCESS:
      return {
        ...state,
        registerBroLoading: false,
        investAddress: action.data.investAddress || '',
        investAmount: action.data.investAmount || 0,
        investToken: action.data.token || '',
        investedAt: action.data.investedAt || '',
        txHash: action.data.txHash || ''
      };

    case actionTypes.FETCH_BRO_WHITELIST:
      return {
        ...state,
        fetchRegistryLoading: true
      };

    case actionTypes.FETCH_BRO_WHITELIST_SUCCESS:
      return {
        ...state,
        fetchRegistryLoading: false,
        investAddress: action.data.investAddress || '',
        investAmount: action.data.investAmount || 0,
        investToken: action.data.token || '',
        investedAt: action.data.investedAt || '',
        txHash: action.data.txHash || ''
      };

    case actionTypes.FETCH_BRO_TOTAL:
      return {
        ...state,
        totalAmount: action.data.totalAmount,
        totalCount: action.data.totalCount || 0,
        fetchBroTotalLoading: false
      };

    default:
      return state;
  }
};
