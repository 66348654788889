import * as actionTypes from '../types';

const initialState = {
  userId: null,
  userData: {},
  wallet: '',
  userReferralData: [],
  historyData: [],
  userDeviceInfo: {},
  userToken: '',
  refreshToken: '',
  tfaToken: '',
  tfaEnabled: false,
  qr: null,
  txId: null,
  sig: null,
  tozBalance: 0,
  investorData: null,
  isClaimed: false,

  chain: null,

  loginError: null,
  loginErrorStatus: null,
  tfaLoginError: null,
  signUpError: null,
  signOutError: null,
  verifyEmailError: null,
  updateAccountError: null,
  updateWalletError: null,
  updatePasswordError: null,
  disable2FAError: null,
  fetch2FAQrCodeError: null,
  enable2FAError: null,
  forgotPasswordError: null,
  resetPasswordError: null,
  fetchTxIdError: null,

  loginLoading: false,
  tfaLoginLoading: null,
  signUpLoading: false,
  signOutLoading: false,
  verifyEmailLoading: false,
  updateAccountLoading: false,
  updateWalletLoading: false,
  updatePasswordLoading: false,
  disable2FALoading: false,
  fetch2FAQrCodeLoading: false,
  enable2FALoading: false,
  forgotPasswordLoading: false,
  resetPasswordLoading: false,
  fetchTxIdLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_SELECT_CHAIN:
      return {
        ...state,
        chain: action.payload
      };

    case actionTypes.USER_DEVICE_INFO:
      return {
        ...state,
        userDeviceInfoLoading: true
      };

    case actionTypes.USER_DEVICE_INFO_SUCCESS:
      return {
        ...state,
        userDeviceInfoLoading: false,
        userDeviceInfoError: null,
        userDeviceInfo: action.data
      };

    case actionTypes.USER_DEVICE_INFO_ERROR:
      return {
        ...state,
        userDeviceInfoLoading: false,
        userDeviceInfoError: action.errorMsg
      };

    case actionTypes.USER_LOGIN:
      return {
        ...state,
        loginLoading: true
      };

    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginError: null,
        userToken: action.data.token,
        refreshToken: action.data.refreshToken,
        userId: action.data.user.userId,
        userData: action.data.user.userData || {},
        address: action.data.user.address || {},
        tozBalance: action.data.user.userData.tozBalance || 0,
        wallet: action.data.user.wallet || '',
        isClaimed: action.data.user.userData.isClaimed
      };

    case actionTypes.USER_LOGIN_ERROR:
      return {
        ...state,
        loginLoading: false,
        loginErrorStatus: action.status,
        loginError: action.errorMsg
      };

    case actionTypes.USER_LOGIN_ERROR_RESET:
      return {
        ...state,
        loginLoading: false,
        loginError: null
      };

    case actionTypes.USER_RESEND_VERIFY:
      return {
        ...state,
        resendVerifyLoading: true
      };

    case actionTypes.USER_RESEND_VERIFY_SUCCESS:
      return {
        ...state,
        resendVerifyLoading: false,
        resendVerifyError: null,
        loginError: null
      };

    case actionTypes.USER_RESEND_VERIFY_ERROR:
      return {
        ...state,
        resendVerifyLoading: false,
        resendVerifyError: action.errorMsg
      };

    case actionTypes.USER_TFA_ENABLED:
      return {
        ...state,
        loginLoading: false,
        loginError: null,
        tfaToken: action.data.token,
        tfaEnabled: true,
        qr: action.data.qr
      };

    case actionTypes.USER_TFA_LOGIN:
      return {
        ...state,
        tfaLoginLoading: true
      };

    case actionTypes.USER_TFA_LOGIN_SUCCESS:
      return {
        ...state,
        tfaToken: '',
        qr: null,
        tfaLoginLoading: false,
        tfaLoginError: null,
        tfaEnabled: true,
        userToken: action.data.token,
        refreshToken: action.data.refreshToken,
        userId: action.data.user.userId,
        userData: action.data.user.userData || {},
        address: action.data.user.address || {},
        tozBalance: action.data.user.userData.tozBalance || 0
      };

    case actionTypes.USER_TFA_LOGIN_ERROR:
      return {
        ...state,
        tfaLoginLoading: false,
        tfaLoginError: action.errorMsg
      };

    case actionTypes.USER_SIGNUP:
      return {
        ...state,
        signUpLoading: true
      };

    case actionTypes.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        signUpLoading: false,
        signUpError: null
      };

    case actionTypes.USER_SIGNUP_ERROR:
      return {
        ...state,
        signUpLoading: false,
        signUpError: action.errorMsg
      };

    case actionTypes.USER_SIGNUP_ERROR_RESET:
      return {
        ...state,
        signUpLoading: false,
        signUpError: null
      };

    case actionTypes.USER_VERIFY_EMAIL:
      return {
        ...state,
        verifyEmailLoading: true
      };

    case actionTypes.USER_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmailLoading: false,
        verifyEmailError: null
      };

    case actionTypes.USER_VERIFY_EMAIL_ERROR:
      return {
        ...state,
        verifyEmailLoading: false,
        verifyEmailError: action.errorMsg
      };

    case actionTypes.USER_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordLoading: true
      };

    case actionTypes.USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordError: null
      };

    case actionTypes.USER_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordError: action.errorMsg
      };

    case actionTypes.USER_RESET_PASSWORD:
      return {
        ...state,
        resetPasswordLoading: true
      };

    case actionTypes.USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: null
      };

    case actionTypes.USER_RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: action.errorMsg
      };

    case actionTypes.FETCH_CURRENT_USER:
      return {
        ...state,
        fetchUserLoading: true
      };

    case actionTypes.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        fetchUserLoading: false,
        fetchUserError: null,
        userToken: action.data.token,
        refreshToken: action.data.refreshToken,
        userId: action.data.user.userId,
        tfaEnabled: action.data.user.tfaEnabled,
        userData: action.data.user.userData || {},
        address: action.data.user.address || {},
        tozBalance: action.data.user.userData.tozBalance || 0,
        wallet: action.data.user.wallet || '',
        investorData: action.data.user.investorData,
        isClaimed: action.data.user.userData.isClaimed
      };

    case actionTypes.FETCH_CURRENT_USER_ERROR:
      return {
        ...state,
        fetchUserLoading: false,
        fetchUserError: action.errorMsg
      };

    case actionTypes.FETCH_USER_REFERRAL:
      return {
        ...state,
        fetchUserReferralLoading: true
      };

    case actionTypes.FETCH_USER_REFERRAL_SUCCESS:
      return {
        ...state,
        fetchUserReferralLoading: false,
        fetchUserReferralError: null,
        userReferralData: action.data.refData
      };

    case actionTypes.FETCH_USER_REFERRAL_ERROR:
      return {
        ...state,
        fetchUserReferralLoading: false,
        fetchUserReferralError: action.errorMsg
      };

    case actionTypes.FETCH_USER_HISTORY:
      return {
        ...state,
        fetchUserHistoryLoading: true
      };

    case actionTypes.FETCH_USER_HISTORY_SUCCESS:
      return {
        ...state,
        fetchUserHistoryLoading: false,
        fetchUserHistoryError: null,
        historyData: action.data.historyData
      };

    case actionTypes.FETCH_USER_HISTORY_ERROR:
      return {
        ...state,
        fetchUserHistoryLoading: false,
        fetchUserHistoryError: action.errorMsg
      };

    case actionTypes.USER_SIGNOUT:
      return {
        ...state,
        signOutLoading: true
      };

    case actionTypes.USER_SIGNOUT_SUCCESS:
      return {
        ...state,
        userId: null,
        userData: {},
        userToken: '',
        refreshToken: ''
      };

    case actionTypes.USER_SIGNOUT_ERROR:
      return {
        ...state,
        signOutLoading: false,
        signOutError: action.errorMsg
      };

    case actionTypes.UPDATE_ACCOUNT_INFO:
      return {
        ...state,
        updateAccountLoading: true
      };

    case actionTypes.UPDATE_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        updateAccountLoading: false,
        userData: action.data.userData || {}
      };

    case actionTypes.UPDATE_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        updateAccountLoading: false,
        updateAccountError: action.data.message || action.errorMsg
      };

    case actionTypes.UPDATE_WALLET_INFO:
      return {
        ...state,
        updateWalletLoading: true
      };

    case actionTypes.UPDATE_WALLET_INFO_SUCCESS:
      return {
        ...state,
        updateWalletLoading: false,
        wallet: action.data.wallet || ''
      };

    case actionTypes.UPDATE_WALLET_INFO_ERROR:
      return {
        ...state,
        updateWalletLoading: false,
        updateWalletError: action.data.message || action.errorMsg
      };

    case actionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        updatePasswordLoading: true
      };

    case actionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordError: null
      };

    case actionTypes.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordError: action.errorMsg
      };

    case actionTypes.DISABLE_TFA:
      return {
        ...state,
        disable2FALoading: true
      };

    case actionTypes.DISABLE_TFA_SUCCESS:
      return {
        ...state,
        tfaEnabled: false,
        disable2FALoading: false,
        disable2FAError: null
      };

    case actionTypes.DISABLE_TFA_ERROR:
      return {
        ...state,
        disable2FALoading: false,
        disable2FAError: action.errorMsg
      };

    case actionTypes.FETCH_2FA_QR:
      return {
        ...state,
        fetch2FAQrCodeLoading: true
      };

    case actionTypes.FETCH_2FA_QR_SUCCESS:
      return {
        ...state,
        qr: action.data.qr,
        fetch2FAQrCodeLoading: false,
        fetch2FAQrCodeError: null
      };

    case actionTypes.FETCH_2FA_QR_ERROR:
      return {
        ...state,
        fetch2FAQrCodeLoading: false,
        fetch2FAQrCodeError: action.errorMsg
      };

    case actionTypes.ENABLE_TFA:
      return {
        ...state,
        enable2FALoading: true
      };

    case actionTypes.ENABLE_TFA_SUCCESS:
      return {
        ...state,
        tfaEnabled: true,
        enable2FALoading: false,
        enable2FAError: null,
        qr: null
      };

    case actionTypes.ENABLE_TFA_ERROR:
      return {
        ...state,
        enable2FALoading: false,
        enable2FAError: action.errorMsg,
        qr: null
      };

    case actionTypes.VERIFY_TFA:
      return {
        ...state,
        enable2FALoading: true
      };

    case actionTypes.VERIFY_TFA_SUCCESS:
      return {
        ...state,
        tfaEnabled: true,
        enable2FALoading: false,
        enable2FAError: null,
        qr: null
      };

    case actionTypes.VERIFY_TFA_ERROR:
      return {
        ...state,
        enable2FALoading: false,
        enable2FAError: action.errorMsg,
        qr: null
      };

    case actionTypes.UPDATE_INVESTOR_DATA:
      return {
        ...state,
        investorData: true
      };

    case actionTypes.UPDATE_INVESTOR_DATA_SUCCESS:
      return {
        ...state,
        investorData: action.data.investorData
      };

    case actionTypes.UPDATE_INVESTOR_DATA_ERROR:
      return {
        ...state,
        enable2FALoading: false,
        enable2FAError: action.errorMsg,
        qr: null
      };

    default:
      return state;
  }
};
