import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

// import asyncComponent from './components/Routes/AsyncComponent';
import AppliedRoute from './components/Routes/AppliedRoute';
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute';
import UnauthenticatedRoute from './components/Routes/UnauthenticatedRoute';
import PageLoader from './components/Routes/PageLoader';
import SinglePost from './containers/Blogs/SinglePost';
import Terms from './containers/Toc/Terms';
import PrivacyPolicy from './containers/Toc/PrivacyPolicy';

const AsyncLogin = Loadable({
  loader: () => import('./containers/Auth/Login'),
  loading: PageLoader
});
const AsyncForgot = Loadable({
  loader: () => import('./containers/Auth/ForgotPassword'),
  loading: PageLoader
});
const AsyncReset = Loadable({
  loader: () => import('./containers/Auth/ResetPassword'),
  loading: PageLoader
});

const AsyncSignup = Loadable({
  loader: () => import('./containers/Auth/SignUp'),
  loading: PageLoader
});
const AsyncHome = Loadable({
  loader: () => import('./containers/Home'),
  loading: PageLoader
});
const AsyncBlogs = Loadable({
  loader: () => import('./containers/Blogs'),
  loading: PageLoader
});
const AsyncExchange = Loadable({
  loader: () => import('./containers/Exchange'),
  loading: PageLoader
});
const AsyncTozexLaunch = Loadable({
  loader: () => import('./containers/TozexLaunch'),
  loading: PageLoader
});
const AsyncBRO = Loadable({
  loader: () => import('./containers/BRO'),
  loading: PageLoader
});
const AsyncProfile = Loadable({
  loader: () => import('./containers/Account/index'),
  loading: PageLoader
});
const AsyncConfirmation = Loadable({
  loader: () => import('./containers/Confirmation'),
  loading: PageLoader
});
const AsyncNotFound = Loadable({
  loader: () => import('./containers/NotFound'),
  loading: PageLoader
});
const AsyncMaintenance = Loadable({
  loader: () => import('./containers/Maintenance'),
  loading: PageLoader
});

// const AsyncNotFound = asyncComponent(() => import("./containers/NotFound"));

export default ({ childProps }) => (
  <Switch>
    {/*<AppliedRoute path="/" component={AsyncMaintenance} props={childProps} />*/}

    <AppliedRoute exact path="/" component={AsyncHome} props={childProps} />

    <AppliedRoute exact path="/terms" component={Terms} props={childProps} />

    <AppliedRoute exact path="/policy" component={PrivacyPolicy} props={childProps} />

    <AppliedRoute exact path="/blogs" component={AsyncBlogs} props={childProps} />

    <AppliedRoute exact path="/blogs/:id" component={SinglePost} props={childProps} />

    <AuthenticatedRoute exact path="/exchange" component={AsyncExchange} props={childProps} />

    <AuthenticatedRoute
      exact
      path="/tozex-launch/:active?/:address?/:type?"
      component={AsyncTozexLaunch}
      props={childProps}
    />

    {/*<AuthenticatedRoute exact path="/tozex-launch/buy-crypto/:status" component={AsyncTozexLaunch} props={childProps} />*/}

    <AuthenticatedRoute
      exact
      path="/tozex-launch/buy-crypto/:redirectTo"
      component={AsyncTozexLaunch}
      props={childProps}
    />

    <AuthenticatedRoute exact path="/bro-dashboard" component={AsyncBRO} props={childProps} />

    <AuthenticatedRoute exact path="/myaccount" component={AsyncProfile} props={childProps} />

    <Route exact path="/auth/maintenance" component={AsyncMaintenance} props={childProps} />

    <UnauthenticatedRoute exact path="/auth/confirmation/:token" component={AsyncConfirmation} props={childProps} />

    <UnauthenticatedRoute exact path="/auth/login" component={AsyncLogin} props={childProps} />

    <UnauthenticatedRoute exact path="/auth/signup" component={AsyncSignup} props={childProps} />

    <UnauthenticatedRoute exact path="/auth/forgot-password" component={AsyncForgot} props={childProps} />

    <UnauthenticatedRoute exact path="/auth/reset-password/:token" component={AsyncReset} props={childProps} />

    <Route component={AsyncNotFound} />
  </Switch>
);
