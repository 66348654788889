import * as actionTypes from '../types';

const initialState = {
  mycontracts: [],
  publicContracts: [],
  selectedContract: [],

  fetchContractError: null,
  retrieveContractError: null,
  updateContractError: null,
  addMyContractError: null,

  myContractsLoading: false,
  retrieveContractLoading: false,
  addContractLoading: false,
  updateContractLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PUBLIC_CONTRACTS:
      return {
        ...state,
        publicContracts: action.data.contracts || []
      };

    case actionTypes.FETCH_MY_CONTRACTS:
      return {
        ...state,
        myContractsLoading: true
      };

    case actionTypes.FETCH_MY_CONTRACTS_SUCCESS:
      return {
        ...state,
        myContractsLoading: false,
        mycontracts: action.data.contracts || []
      };

    case actionTypes.FETCH_MY_CONTRACTS_ERROR:
      return {
        ...state,
        myContractsLoading: false,
        fetchContractError: action.errorMsg
      };
    case actionTypes.ADD_MY_CONTRACT:
      return {
        ...state,
        addContractLoading: true
      };

    case actionTypes.ADD_MY_CONTRACT_SUCCESS:
      return {
        ...state,
        addContractLoading: false
      };

    case actionTypes.ADD_MY_CONTRACT_ERROR:
      return {
        ...state,
        addContractLoading: false,
        addMyContractError: action.errorMsg
      };

    case actionTypes.UPDATE_CONTRACT:
      return {
        ...state,
        updateContractLoading: true
      };

    case actionTypes.UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        mycontracts: action.data.contracts || [],
        updateContractLoading: false
      };

    case actionTypes.UPDATE_CONTRACT_ERROR:
      return {
        ...state,
        updateContractLoading: false,
        updateMyContractError: action.errorMsg
      };

    case actionTypes.FETCH_CONTRACT:
      return {
        ...state,
        retrieveContractLoading: true
      };

    case actionTypes.FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        retrieveContractLoading: false,
        selectedContract: action.data.contract || []
      };

    case actionTypes.FETCH_CONTRACT_ERROR:
      return {
        ...state,
        retrieveContractLoading: false,
        retrieveContractError: action.errorMsg
      };

    default:
      return state;
  }
};
