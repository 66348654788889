import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import SweetAlert from 'react-bootstrap-sweetalert';

import authActions from '../../redux/actions/auth';

import { renderSvg } from '../../constant/helpers';

class SignUp extends Component {
  state = {
    email: '',
    lastName: '',
    firstName: '',
    password: '',
    confirmPassword: '',
    country: '',
    address: '',
    postalcode: '',
    phone: '',
    showAlert: false
  };

  changeValue = field => e => {
    this.setState({
      [field]: e.target.value
    });
  };

  validateEmail = () => {
    return /^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/.test(this.state.email);
  };

  onSubmit = e => {
    e.preventDefault();

    let { email, lastName, firstName, password, confirmPassword, country, address, postalcode, phone } = this.state;
    email = email.trim();
    lastName = lastName.trim();
    firstName = firstName.trim();
    password = password.trim();
    confirmPassword = confirmPassword.trim();

    if (email && this.validateEmail() && lastName && firstName && password && confirmPassword) {
      this.props
        .signUp({
          email,
          lastName,
          firstName,
          password,
          confirmPassword,
          country,
          address,
          postalcode,
          phone
        })
        .then(() => {
          this.setState({
            showAlert: true
          });
        });
    }
  };

  hideAlert = () => {
    this.setState({
      showAlert: false
    });

    // this.props.toggleModal('LogIn');
  };

  render() {
    const {
      email,
      lastName,
      firstName,
      password,
      confirmPassword,
      showAlert,
      country,
      address,
      postalcode,
      phone
    } = this.state;
    const { error, loading, toggleModal } = this.props;

    return (
      <Paper className="tz-modal__auth p-4">
        <h3 className="font-weight-normal text-center mb-3">Create your Tozex account</h3>

        <IconButton className="tz-modal__auth__close" onClick={() => toggleModal()}>
          {renderSvg('tz-modal__auth__close__icon', 'close')}
        </IconButton>

        <form onSubmit={this.onSubmit}>
          <FormControl required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              autoFocus
              type="email"
              id="email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={this.changeValue('email')}
            />
          </FormControl>

          <FormControl required fullWidth className="mt-2">
            <InputLabel htmlFor="email">First Name</InputLabel>
            <Input
              id="first-name"
              name="first-name"
              autoComplete="first-name"
              value={firstName}
              onChange={this.changeValue('firstName')}
            />
          </FormControl>

          <FormControl required fullWidth className="mt-2">
            <InputLabel htmlFor="email">Last Name</InputLabel>
            <Input
              id="last-name"
              name="last-name"
              autoComplete="last-name"
              value={lastName}
              onChange={this.changeValue('lastName')}
            />
          </FormControl>

          <FormControl fullWidth className="mt-2">
            <InputLabel htmlFor="country">Country</InputLabel>
            <Input
              id="country"
              name="country"
              autoComplete="country"
              value={country}
              onChange={this.changeValue('country')}
            />
          </FormControl>

          <FormControl fullWidth className="mt-2">
            <InputLabel htmlFor="address">Address</InputLabel>
            <Input
              id="address"
              name="address"
              autoComplete="address"
              value={address}
              onChange={this.changeValue('address')}
            />
          </FormControl>

          <FormControl fullWidth className="mt-2">
            <InputLabel htmlFor="postalcode">Postal Code</InputLabel>
            <Input
              id="postalcode"
              name="postalcode"
              autoComplete="postalcode"
              value={postalcode}
              onChange={this.changeValue('postalcode')}
            />
          </FormControl>

          <FormControl fullWidth className="mt-2">
            <InputLabel htmlFor="phone-number">Phone Number</InputLabel>
            <Input
              id="phone-number"
              name="phone-number"
              autoComplete="phone-number"
              value={phone}
              onChange={this.changeValue('phone')}
            />
          </FormControl>

          <FormControl required fullWidth className="mt-2">
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              type="password"
              id="password"
              name="password"
              autoComplete="current-password"
              value={password}
              onChange={this.changeValue('password')}
            />
          </FormControl>

          <FormControl required fullWidth className="mt-2">
            <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
            <Input
              type="password"
              id="confirm-password"
              name="confirm-password"
              value={confirmPassword}
              onChange={this.changeValue('confirmPassword')}
            />
          </FormControl>

          {error && (
            <FormControl fullWidth className="mt-2">
              <FormLabel error>{error}</FormLabel>
            </FormControl>
          )}

          <FormControl fullWidth className="mt-3">
            <Button type="submit" variant="contained" size="large" color="primary" fullWidth disabled={loading}>
              Sign up
            </Button>

            <Button
              type="submit"
              variant="contained"
              size="large"
              color="secondary"
              fullWidth
              className="mt-2"
              onClick={() => toggleModal('LogIn')}
            >
              Go to Log in
            </Button>
          </FormControl>
        </form>

        <SweetAlert
          success
          title="Account has been successfully created."
          show={showAlert}
          closeOnClickOutside={true}
          showConfirm={false}
          onConfirm={() => {
            return null;
          }}
          onCancel={this.hideAlert}
        >
          A verification email has been sent. Please verify your email address.
        </SweetAlert>
      </Paper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  error: auth.signUpError,
  loading: auth.signUpLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signUp: data => authActions.signUp(data)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
