import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import authActions from '../../redux/actions/auth';
import { renderSvg } from '../../constant/helpers';

const $ = window.$;

class InternalHeader extends Component {
  state = {
    menuOpened: false,
    scrollTop: true
  };

  componentDidMount() {
    // const { isAuthenticated, path } = this.props;
    // if (!isAuthenticated && path !== '/') {
    //   this.props.redirectToHome();
    // }

    window.onscroll = this.onScroll;
  }

  onScroll = e => {
    if ($(e.target).scrollTop() > 0) {
      this.setState({
        scrollTop: false
      });
    } else {
      this.setState({
        scrollTop: true
      });
    }
  };

  toggleMenu = () => {
    this.setState({
      menuOpened: !this.state.menuOpened
    });
  };

  signOut = e => {
    e.preventDefault();

    this.props.signOut(this.props.token).then(() => {
      this.props.redirectToHome();
    });
  };

  render() {
    const { menuOpened, scrollTop } = this.state;
    const { path, isAuthenticated } = this.props;

    return (
      <header className="tz-header d-flex align-items-center justify-content-between">
        {/*<nav className={scrollTop ? '' : 'fixed-nav'}>*/}
        {/*<div className="container">*/}
        {/*<div className="row">*/}
        {/*<div className="col-xl-12">*/}
        {/*<a href="#" className="logotype">*/}
        {/*{renderSvg('tz-header__logo__icon', 'logo')}*/}
        {/*</a>*/}

        {/*<span className="menu-button" onClick={() => this.toggleMenu()}>*/}
        {/*<span />*/}
        {/*<span />*/}
        {/*<span />*/}
        {/*</span>*/}

        {/*<a href="#" className="btn btn_small gradient_button" onClick={() => this.props.toggleModal('SignUp')}>*/}
        {/*Sign Up*/}
        {/*</a>*/}
        {/*<a href="javascript:;" className="btn btn_small light_button mr-3" onClick={() => this.props.toggleModal('LogIn')}>*/}
        {/*Login*/}
        {/*</a>*/}
        {/*<ul className="site_menu">*/}
        {/*<li>*/}
        {/*<a href="#Tozex" className="active">*/}
        {/*What is TOZEX?*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="#Invest" className="nav-link" data-toggle="dropdown">*/}
        {/*Invest*/}
        {/*</a>*/}

        {/*<ul className="navbar-nav">*/}
        {/*<li>*/}
        {/*<a className="dropdown-item" href="#Tozex">*/}
        {/*Ecosystem*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a className="dropdown-item" href="#Multiplatform">*/}
        {/*Multiplatform*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a className="dropdown-item" href="#Users">*/}
        {/*Users*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="#Roadmap" className="nav-link" data-toggle="dropdown">*/}
        {/*Roadmap*/}
        {/*</a>*/}

        {/*<ul className="navbar-nav">*/}
        {/*<li>*/}
        {/*<a className="dropdown-item" href="#Marketing">*/}
        {/*Market Positioning*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a className="dropdown-item" href="#BRO">*/}
        {/*Believer Reward Offering*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a className="dropdown-item" href="#Funding">*/}
        {/*Funding structure*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="#Team" className="">*/}
        {/*Team*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="/exchange" className="">*/}
        {/*Trading*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="/tozex-launch" className="">*/}
        {/*TokenPad*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="https://medium.com/@tozex" target="_blank" className="">*/}
        {/*Blog*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</nav>*/}

        {/*<div className={menuOpened ? 'mobile-menu active-menu' : 'mobile-menu'}>*/}
        {/*<span className="close-menu-button" onClick={() => this.toggleMenu()}>*/}
        {/*<span />*/}
        {/*<span />*/}
        {/*</span>*/}

        {/*<a href="#" className="logotype">*/}
        {/*{renderSvg('tz-header__logo__icon', 'logo')}*/}
        {/*</a>*/}
        {/*<ul>*/}
        {/*<li>*/}
        {/*<a href="#Tozex" className="active">*/}
        {/*What is TOZEX?*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="#Invest" className="nav-link" data-toggle="dropdown">*/}
        {/*Invest*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="#Roadmap" className="nav-link" data-toggle="dropdown">*/}
        {/*Roadmap*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="#Team" className="">*/}
        {/*Team*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="/exchange" className="">*/}
        {/*Trading*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="/tozex-launch" className="">*/}
        {/*TokenPad*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="#Team">Team</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a href="https://medium.com/@tozex" target="_blank" className="">*/}
        {/*Blog*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*<a href="signup.html" className="btn btn_small btn-block gradient_button">*/}
        {/*Sign Up*/}
        {/*</a>*/}
        {/*<a href="login.html" className="btn btn_small btn-block outline_button">*/}
        {/*Login*/}
        {/*</a>*/}
        {/*</div>*/}

        <div className={`d-md-none${menuOpened ? ' active' : ''}`}>
          <IconButton className="tz-header__menu" onClick={this.toggleMenu}>
            {menuOpened ? renderSvg('tz-header__menu__icon', 'close') : renderSvg('tz-header__menu__icon', 'menu')}
          </IconButton>
        </div>

        <div className="tz-header__content d-flex align-items-center justify-content-center">
          <Link to="/tozex-launch" className={`tz-header__link${path.includes('tozex-launch') ? ' active' : ''}`}>
            Tozex Launch
          </Link>
          <Link to="/exchange" className={`tz-header__link${path.includes('exchange') ? ' active' : ''}`}>
            Exchange
          </Link>

          {isAuthenticated ? (
            <Link to="/logout" className="tz-header__link tz-header__link--logout" onClick={this.signOut}>
              Logout
            </Link>
          ) : (
            <div className="mt-3 mt-md-0 mb-4 mb-md-0">
              <Button
                variant="outlined"
                size="medium"
                className="tz-header__auth"
                onClick={() => this.props.toggleModal('LogIn')}
              >
                Log In
              </Button>
              <Button
                variant="outlined"
                size="medium"
                className="tz-header__auth"
                onClick={() => this.props.toggleModal('SignUp')}
              >
                Sign Up
              </Button>
            </div>
          )}
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  token: auth.userToken,
  name: `${auth.userData.firstName} ${auth.userData.lastName}`
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirectToHome: () => push('/'),
      signOut: token => authActions.signOut(token)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalHeader);
