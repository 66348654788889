import React, { Component } from 'react';

import { renderSvg } from '../../constant/helpers';

import Footer from '../../components/Footer';
import glBg from '../../assets/img/section3_tozex-ecosystem.svg';
import smartcontractImg from '../../assets/img/smart_contract_800px.jpg';
import interfaceImg from '../../assets/img/interface2_800px.jpg';
import transparencyImg from '../../assets/img/transparency_800px.jpg';

class SinglePost extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="post-header" className="post-header valign-bottom">
          <div className="container">
            <div className="row">
              <ul className="post-category">
                <li>
                  <a href="#">Financial</a>
                </li>
              </ul>
              <div className="col-xl-12">
                <div className="title">
                  <h1>Here's how much it costs to buy a crypto in the biggest markets of 2019</h1>
                </div>
                <div className="blog-meta pb-5">
                  <span className="blog-meta-date">
                    <i className="fas fa-calendar-alt" />12.02.2019
                  </span>
                  <span className="blog-meta-tags">
                    <i className="fas fa-folder-open" />
                    <a href="#">Financial</a>, <a href="#">Blockchain</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="post-wrap p-large">
          <div className="container">
            <div className="row">
              <div className="col-xl-2 col-md-12 post-author">
                <aside>
                  <div className="user-info">
                    <div className="photo" />
                    <h3>Christophe Ozcan</h3>
                    <h4>CTO</h4>
                  </div>
                  <span className="share">Share:</span>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-paper-plane" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-google-plus-g" />
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
              <div className="col-xl-10 post-content">
                <h2>
                  The last person we talked to said this would be ready but what's the real problem we're trying to
                  solve here?
                </h2>
                <p>
                  We exceed the clients' expectations the website doesn't have the theme i was going for we try your
                  eye, but can you change everything? so you are lucky to even be doing this for us so make it original,
                  and you can get my logo from facebook what is a hamburger menu. Make it pop I want you to take it to
                  the next level can you turn it around in photoshop so we can see more of the front, and we need more
                  images of groups of people having non-specific types of fun, nor we are a non-profit organization, yet
                  I like it, but can the snow look a little warmer, nor I want you to take it to the next level.
                </p>
                <p>
                  Anyway, you are the designer, you know what to do i know you've made thirty iterations but can we go
                  back to the first one that was the best version but anyway, you are the designer, you know what to do.
                  We don't need a contract, do we this looks perfect.
                </p>
                <p>
                  Prairie dogging low-hanging fruit. Show pony staff engagement, so curate. Game-plan hard stop I have
                  zero cycles for this. Put your feelers out we just need to put these last issues to bed. Make sure to
                  include in your wheelhouse ladder up / ladder back to the strategy digital literacy but obviously. Not
                  enough bandwidth highlights for open door policy gain traction. High touch client those options are{' '}
                </p>
                <img src="/img/post/image-post.jpg" alt="" />
                <p>
                  Expectations the website doesn't have the theme i was going for we try your eye, but can you change
                  everything? so you are lucky to even be doing this for us so make it original, and you can get my logo
                  from facebook what is a hamburger menu. Make it pop I want you to take it to the next level can you
                  turn it around in photoshop so we can see more of the front, and we need more images of groups of
                  people having non-specific types of fun, nor we are a non-profit organization, yet I like it, but can
                  the snow look a little warmer, nor I want you to take it to the next level.
                </p>
                <q>
                  I have zero cycles for this proceduralize, so baseline the procedure and samepage your department
                  drink from the firehose we need to button up our approach or back to the drawing-board.
                </q>
                <p>
                  We exceed the clients' expectations the website doesn't have the theme i was going for we try your
                  eye, but can you change everything? so you are lucky to even be doing this for us so make it original,
                  and you can get my logo from facebook what is a hamburger menu. Make it pop I want you to take it to
                  the next level can you turn it around in photoshop so we can see more of the front, and we need more
                  images of groups of people having non-specific types of fun, nor we are a non-profit organization, yet
                  I like it, but can the snow look a little warmer, nor I want you to take it to the next level.
                </p>
                <p>
                  Anyway, you are the designer, you know what to do i know you've made thirty iterations but can we go
                  back to the first one that was the best version but anyway, you are the designer, you know what to do.
                  We don't need a contract, do we this looks perfect.
                </p>
                <p>
                  Prairie dogging low-hanging fruit. Show pony staff engagement, so curate. Game-plan hard stop I have
                  zero cycles for this. Put your feelers out we just need to put these last issues to bed. Make sure to
                  include in your wheelhouse ladder up / ladder back to the strategy digital literacy but obviously. Not
                  enough bandwidth highlights for open door policy gain traction. High touch client those options are.{' '}
                </p>
              </div>
              <div className="col-xl-12 more-articles">
                <h2>More from this category</h2>
                <div className="articles wow fadeInUp">
                  <article className="blog-card blog-card-light">
                    <a href="/blogs/81291" className="image">
                      <img src="/img/blog/article-1.jpg" alt="" />
                    </a>
                    <div className="article-content">
                      <a href="#" className="category">
                        <i className="far fa-folder" /> Financial
                      </a>
                      <a href="#" className="date">
                        <i className="far fa-clock" /> 04.07.2018
                      </a>
                      <a href="#" className="title">
                        <h3>Lower supply is generating high price growth</h3>
                      </a>
                    </div>
                  </article>
                  <article className="blog-card blog-card-light">
                    <a href="/blogs/81291" className="image">
                      <img src="/img/blog/article-2.jpg" alt="" />
                    </a>
                    <div className="article-content">
                      <a href="#" className="category">
                        <i className="far fa-folder" /> Events
                      </a>
                      <a href="#" className="date">
                        <i className="far fa-clock" /> 22.09.2018
                      </a>
                      <a href="#" className="title">
                        <h3>Introduction cryptocurrency bills to Congress</h3>
                      </a>
                    </div>
                  </article>
                  <article className="blog-card blog-card-light">
                    <a href="/blogs/81291" className="image">
                      <img src="/img/blog/article-3.jpg" alt="" />
                    </a>
                    <div className="article-content">
                      <a href="#" className="category">
                        <i className="far fa-folder" /> Markets
                      </a>
                      <a href="#" className="date">
                        <i className="far fa-clock" /> 28.08.2018
                      </a>
                      <a href="#" className="title">
                        <h3>Is relative value investing time finally here?</h3>
                      </a>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default SinglePost;
