import * as actionTypes from '../types';

const initialState = {
  tokenClaimHash: '',

  claimTokenError: null,
  claimTokenLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_WITHDRAW:
      return {
        ...state,
        claimTokenLoading: true
      };

    case actionTypes.USER_WITHDRAW_SUCCESS:
      return {
        ...state,
        claimTokenLoading: false,
        tokenClaimHash: action.data.txhash || null
      };

    case actionTypes.USER_WITHDRAW_ERROR:
      return {
        ...state,
        claimTokenLoading: false,
        claimTokenError: action.data.message || action.errorMsg
      };

    default:
      return state;
  }
};
