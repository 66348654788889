import React from 'react';

import { LogIn, SignUp, TFA } from '../../components/Modal';

const Modals = props => {
  const { isAuthenticated, modal, toggleModal } = props;

  return (
    <div className="tz-modal d-flex align-items-center justify-content-center">
      {!isAuthenticated && modal === 'LogIn' && <LogIn toggleModal={toggleModal} />}

      {!isAuthenticated && modal === 'SignUp' && <SignUp toggleModal={toggleModal} />}

      {!isAuthenticated && modal === 'TFA' && <TFA toggleModal={toggleModal} />}
    </div>
  );
};

export default Modals;
