import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';

import authActions from '../../redux/actions/auth';

import { renderSvg } from '../../constant/helpers';

class LogIn extends Component {
  state = {
    email: '',
    password: ''
  };

  changeValue = field => e => {
    this.setState({
      [field]: e.target.value
    });
  };

  validateEmail = () => {
    return /^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/.test(this.state.email);
  };

  onSubmit = e => {
    e.preventDefault();

    let { email, password } = this.state;
    email = email.trim();
    password = password.trim();

    if (email && this.validateEmail() && password) {
      this.props
        .login({
          email,
          password
        })
        .then(tfa => {
          if (tfa) {
            this.props.toggleModal('TFA');
          } else {
            this.props.toggleModal();
          }
        });
    }
  };

  render() {
    const { email, password } = this.state;
    const { error, loading, toggleModal } = this.props;

    return (
      <Paper className="tz-modal__auth p-4">
        <h3 className="font-weight-normal text-center">
          Log in with your
          <br />
          Tozex account
        </h3>

        <IconButton className="tz-modal__auth__close" onClick={() => toggleModal()}>
          {renderSvg('tz-modal__auth__close__icon', 'close')}
        </IconButton>

        <form onSubmit={this.onSubmit}>
          <FormControl required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              autoFocus
              type="email"
              id="email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={this.changeValue('email')}
            />
          </FormControl>

          <FormControl required fullWidth className="mt-2">
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={this.changeValue('password')}
            />
          </FormControl>

          <FormControl required fullWidth className="d-flex flex-row align-items-center justify-content-between mt-2">
            <FormControlLabel
              className="tz-modal__auth__remember"
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />

            <span className="tz-modal__auth__forgot">Forgot password?</span>
          </FormControl>

          {error && (
            <FormControl fullWidth>
              <FormLabel error>{error}</FormLabel>
            </FormControl>
          )}

          <FormControl fullWidth className="mt-3">
            <Button type="submit" variant="contained" size="large" color="primary" fullWidth disabled={loading}>
              Log in
            </Button>

            <Button
              type="submit"
              variant="contained"
              size="large"
              color="secondary"
              fullWidth
              className="mt-2"
              onClick={() => toggleModal('SignUp')}
            >
              Go to Sign up
            </Button>
          </FormControl>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  error: auth.loginError,
  loading: auth.loginLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login: data => authActions.login(data)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogIn);
