import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { renderSvg } from '../../constant/helpers';
import Button from '@material-ui/core/Button/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import newsletterActions from '../../redux/actions/newsletter';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      showAlert: false
    };
  }

  toggleAlert = () => {
    this.setState({
      showAlert: !this.state.showAlert
    });
  };

  subscribe = e => {
    this.props.subscribeNewsletter({ email: this.state.email }).then(res => {
      this.toggleAlert();
    });
  };

  render() {
    return (
      <footer id="Subscribe" className="big-footer pt-md pt-lg-xl ">
        <div className="subscribe pb-md pb-lg-xl">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="title title-center">
                  <span>Subscribe</span>
                  <h2>Join Our Mailing List</h2>
                </div>
              </div>
              <div className="col-xl-12 mt-4 mb-4">
                <form>
                  <input
                    type="text"
                    placeholder="Enter your email"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  <input type="button" onClick={this.subscribe} value="Subscribe" className="btn gradient_button" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container pb-3 pl-xs pr-xs">
          <div className="row">
            <div className="col-xl-3 col-md-3 col-sm-6 pb-2">
              <a href="javascript:;" className="logotype">
                {renderSvg('tz-footer__bottom__logo', 'logo')}
                <p>Financing cryptoasset platform for SMEs</p>
              </a>
            </div>
            <div className="col-xl-2 col-md-3 col-sm-6">
              <h6 className="footer-title">Company</h6>
              <ul>
                <li>
                  <a href="https://crypto4all.com/" target="_blank">
                    Crypto4All
                  </a>
                </li>
                <li>
                  <a
                    href={`https://medium.com/tozex/tokenize-your-business-and-get-funded-f29b7daeb5c4`}
                    target="_blank"
                  >
                    Funding Use Case
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_API_URI}static/tozex_terms_of_service.pdf`} target="_blank">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_API_URI}static/tozex_privacy_policy.pdf`} target="_blank">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-md-3 col-sm-6">
              <h6 className="footer-title">Protocol</h6>
              <ul>
                <li>
                  <a
                    href="https://drive.google.com/file/d/1m_LdMgz5V654BKv6buBYJOJCEJgNwmLy/view?usp=sharing"
                    target="_blank"
                  >
                    Whitepaper
                  </a>
                </li>
                <li>
                  <a href="https://github.com" target="_blank">
                    Github
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-md-3 col-sm-6">
              <h6 className="footer-title">Contacts</h6>
              <ul>
                <li>
                  <span>45 boulevard Victor Hugo, 92110 Clichy, Paris region, France</span>
                </li>

                <li>
                  <a href="https://medium.com/@tozex" target="_blank" className="social-button">
                    <i className="fab fa-medium" />
                  </a>
                  <a href="https://t.me/tozexofficial" target="_blank" className="social-button">
                    <i className="fas fa-paper-plane" />
                  </a>
                  <a href="https://twitter.com/tozexofficial" target="_blank" className="social-button">
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    href="https://fr.linkedin.com/company/tozex-decentralized-finance"
                    target="_blank"
                    className="social-button"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                  <a href="javascript:;" className="social-button">
                    <i className="fab fa-github" />
                  </a>
                  <a href="https://www.facebook.com/tozexofficial/" className="social-button">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
              </ul>
            </div>
            {/*<div className="col-12 copyright">*/}
            {/*<span>© 2019</span>*/}
            {/*<span>Tozex Exchange</span>*/}
            {/*</div>*/}
            <a href="#Home-dark" id="back-to-top" title="Back to top" className="show">
              <i className="fas fa-angle-up" />
            </a>
          </div>
        </div>

        <div className="tz-footer__bottom py-4 text-center">
          {renderSvg('tz-footer__bottom__logo', 'logo')}

          <div className="mt-2 text-white">&copy; 2019 Tozex Exchange V1.0</div>
        </div>

        <SweetAlert
          success
          title="Congrats!"
          show={this.state.showAlert}
          closeOnClickOutside={true}
          showConfirm={true}
          confirmBtnCssClass="gradient_button"
          onConfirm={this.toggleAlert}
          onCancel={this.toggleAlert}
        >
          You are now subscribed to our newsletter!
        </SweetAlert>
      </footer>
    );
  }
}

const mapStateToProps = ({ auth, newsletter }) => ({
  error: newsletter.subscribeNewsletterError,
  loading: newsletter.subscribeNewsletterLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      subscribeNewsletter: data => newsletterActions.subscribeNewsletter(data)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
