import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

import authActions from '../../redux/actions/auth';

import { renderSvg } from '../../constant/helpers';
import Banner from '../../containers/Home/Banner';

const styles = theme => ({
  root: {
    width: '90%'
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2
  },
  resetContainer: {
    padding: theme.spacing.unit * 3
  },
  margin: {
    margin: theme.spacing.unit
  }
});

function getSteps() {
  return [
    'Enable 2FA for your protection',
    'Scan QR Code with Authy App',
    'Backup your secret key and put the code here'
  ];
}

class TFA extends Component {
  state = {
    code: '',
    activeStep: 0
  };

  changeCode = e => {
    this.setState({ code: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    let { code } = this.state;
    code = code.trim();

    if (code) {
      this.props.tfaLogin({ code, token: this.props.token }).then(() => {
        this.props.toggleModal();
      });
    }
  };

  doItLater = () => {
    this.props.denyTfa(this.props.token).then(() => {
      this.props.toggleModal();
      // this.props.history.push('/auth/login');
      window.location.href = '/auth/login';
    });
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  getStepContent = step => {
    const { code, activeStep } = this.state;
    const { qr, error, loading, toggleModal, classes } = this.props;

    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <Typography>
              Protect your account from hackers with Authy app. If you haven’t already, please download it.
            </Typography>
            <div className="mt-2">
              <Button variant="contained" size="medium" color="primary" style={{ marginBottom: 30 }}>
                <a href={'https://authy.com/download/'} target="_blank" rel="noopener noreferrer">
                  Download App
                </a>
              </Button>
            </div>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Typography>Please scan the QR code below with Authy app</Typography>
            <div className="mt-2">
              <img src={qr} className="tz-modal__auth__qr" alt="" />
            </div>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <Typography>
              Please write down or print a copy of the secret key and put the code from Authy app in the below input box
            </Typography>
            <div className="mt-2">
              <form onSubmit={this.onSubmit}>
                <FormControl required fullWidth>
                  <InputLabel htmlFor="code">Code</InputLabel>
                  <Input autoFocus type="text" id="code" name="code" value={code} onChange={this.changeCode} />
                </FormControl>

                {error && (
                  <FormControl fullWidth className="mt-2 text-left">
                    <FormLabel error>{error}</FormLabel>
                  </FormControl>
                )}

                <FormControl fullWidth className="mt-3">
                  <Button type="submit" variant="contained" size="large" color="primary" fullWidth disabled={loading}>
                    Verify
                  </Button>
                </FormControl>
              </form>
            </div>
          </React.Fragment>
        );
      default:
        return 'Unknown step';
    }
  };

  renderTfaQRModal = () => {
    const { code, activeStep } = this.state;
    const { toggleModal, classes } = this.props;
    const steps = getSteps();

    return (
      <Paper className="tz-modal__auth p-4 text-left">
        <h3 className="font-weight-normal text-center">Setup Authy app</h3>

        <Stepper activeStep={activeStep} nonLinear={true} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <div>{this.getStepContent(index)}</div>

                {activeStep < steps.length - 1 && (
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button disabled={activeStep === 0} onClick={this.handleBack} className={classes.button}>
                        Back
                      </Button>

                      <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </div>
                  </div>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <FormControl className="mt-3 text-center">
          <Button variant="contained" size="medium" color="primary" onClick={this.doItLater}>
            Do it later
          </Button>
        </FormControl>
      </Paper>
    );
  };

  renderTfaVerifyModal = () => {
    const { code } = this.state;
    const { qr, error, loading, toggleModal } = this.props;

    return (
      <Paper className="tz-modal__auth p-4 text-center">
        <h3 className="font-weight-normal text-center">
          Two Factor
          <br />
          Authentication
        </h3>

        <IconButton className="tz-modal__auth__close" onClick={() => toggleModal()}>
          {renderSvg('tz-modal__auth__close__icon', 'close')}
        </IconButton>

        {qr ? (
          <img src={qr} className="tz-modal__auth__qr" alt="" />
        ) : (
          <p className="mt-3">
            Please enter the code from
            <br />
            your Authy app
          </p>
        )}

        <form onSubmit={this.onSubmit}>
          <FormControl required fullWidth>
            <InputLabel htmlFor="code">Code</InputLabel>
            <Input autoFocus type="text" id="code" name="code" value={code} onChange={this.changeCode} />
          </FormControl>

          {error && (
            <FormControl fullWidth className="mt-2 text-left">
              <FormLabel error>{error}</FormLabel>
            </FormControl>
          )}

          <FormControl fullWidth className="mt-3">
            <Button type="submit" variant="contained" size="large" color="primary" fullWidth disabled={loading}>
              Verify
            </Button>
          </FormControl>
        </form>
      </Paper>
    );
  };

  render() {
    const { qr } = this.props;

    if (qr) {
      return this.renderTfaQRModal();
    }

    return this.renderTfaVerifyModal();
  }
}

const mapStateToProps = ({ auth }) => ({
  token: auth.tfaToken,
  qr: auth.qr,
  error: auth.tfaLoginError,
  loading: auth.tfaLoginLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      tfaLogin: data => authActions.tfaLogin(data),
      denyTfa: token => authActions.denyTfa(token)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TFA));
