import React, { Component } from 'react';

import Footer from '../../components/Footer';

class Terms extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="post-wrap p-large" style={{ marginTop: '80px' }}>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 post-content">
                <h2>Terms of Service</h2>
                <p>
                  PLEASE READ THESE TERMS OF SERVICE VERY CAREFULLY. IT CONTAINS VERY IMPORTANT INFORMATION REGARDING
                  YOUR RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATION, RISK FACTORS AND EXCLUSIONS THAT MIGHT
                  APPLY TO YOU.
                </p>
                <p>
                  BY ACCESSING OR USING THIS SITE AND PLATFORM, YOU AGREE TO BE LEGALLY BOUND BY THESE TERMS OF SERVICE
                  AND ALL TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT ACCEPT THE TERMS OF USE AND CONDITIONS OUTLINED
                  IN THIS AGREEMENT, DO NOT ACCESS THIS SITE AND DO NOT USE THE SERVICE.
                </p>

                <h3>Global Overview</h3>
                <p>
                  This is an agreement between you and the Company. The website http://www.tozex.io is comprised of
                  various web pages operated by Tozex (the "Site"), and is offered to you conditioned on your acceptance
                  without modification of the terms, conditions and notices contained herein (the "Terms"). Your use of
                  the Site constitutes your agreement to all such Terms. Please read these Terms carefully and keep a
                  copy of them for your reference.
                </p>

                <p>
                  In order to register to use the Tozex platform or any related services from Tozex, Partners or Third
                  Party, you will also be required to accept the Terms, privacy policy terms and any related document
                  disclosed on the Site (“Authorized User Agreement”) made available to you at the time of registration.
                  The Authorized User Agreement, will govern your access to and use of the Tozex Platform and Services.
                </p>
                <p>
                  TOZEX may incorporate or provide access to Third Party Services. By accepting those terms, you hereby
                  accept and agree to terms of use of all Third Party Services providers related to the service offered
                  through the Site such as, without being limited to, Metasmak, Blockpass.io, Ledger, Trezor, Sumsub,
                  Brave or Carbon.money. TOZEX is not responsible for any performance or failure to perform the Third
                  Party Services. It is your responsibility to review the Third Party’s terms and policies before using
                  a Third Party Service as disclosed in their respective website.
                </p>
                <p>
                  Some Third Party Services may request or require access to your personal data. The processing of such
                  data will be handled in accordance with the relevant Third Party’s privacy policy and best practices.
                  We warrant you that we can modify, add or cancel availability of any such Third Party Service on
                  discretionary basis without prior notice. We advise not to share any credential, password, Private
                  Keys, passphrase, or any other sensitive information with any Third Party Service without validating
                  their legitimacy first.
                </p>
                <p>
                  Tozex is a turnkey solution for the tokenization of assets issued by companies, funds, association and
                  other entities (Digital Assets). The Tozex services consist of a hosted infrastructure as a service
                  that enables issuers, through its users, to prepare, create, store, manage and distribute Digital
                  Assets without or within a context of token based fundraising mechanism (“Tozex Services”). In any
                  case and any circumstances, Tozex could be considered as an intermediary between Project holder/Issuer
                  and Investors. Tozex provides Services through the Platform which allows direct relationship between
                  all stakeholders without intermediation of the Company.
                </p>
                <p>
                  Tozex makes the Services available through web based interfaces to be used by the issuer with his own
                  Wallet to :
                </p>
                <ul style={{ listStyleType: 'disc', marginLeft: '15px' }}>
                  <li>Create and manage their digital asset through an issuer dashboard "TokenPad</li>
                  <li>Prepare and manage a token based fundraising campaign through an issuer dashboard “TokenPad”;</li>
                  <li>
                    Enable potential investors to register for a token based offering (“Company Offering”) through the
                    investor’s dashboard “InvestPad” and qualify such investors;
                  </li>
                  <li>
                    create, issue and distribute Digital Assets with or without any financial compensation to investors,
                    partners, team, advisors or customer of the issuer;
                  </li>
                  <li>store Digital Assets by using Digital Asset Vault multi signature.</li>
                </ul>

                <p>
                  We may not make all of the Services available in all markets and jurisdictions and may restrict or
                  prohibit use of all or a portion of the Services from certain jurisdictions. You must not attempt to
                  circumvent any restrictions imposed via the Services, such as by obscuring your IP address or
                  submitting any inaccurate information regarding your location.
                </p>

                <h3>DEFINITIONS</h3>
                <h5>“Account”</h5>
                <p>Means an account which offer an access to the Platform and Third Party Services.</p>

                <h5>“Bitcoin”</h5>
                <p>
                  Digital currency and payment system using peer-to-peer transactions verified by network nodes and
                  recording in a public distributed ledger called blockchain.
                </p>

                <h5>“Bitcoin”</h5>
                <p>
                  Digital currency and payment system using peer-to-peer transactions verified by network nodes and
                  recording in a public distributed ledger called blockchain.
                </p>

                <h5>"Crypto Assets"</h5>
                <p>
                  Means types of digital assets which can be transmitted with blockchain technologies, including but not
                  limited to Bitcoin, Ethereum, Ripple.
                </p>

                <h5>"Customers"</h5>
                <p>
                  Means all stakeholders using Services available trough Platform which includes project
                  holders/issuers, investors and traders.
                </p>

                <h5>"Company"</h5>
                <p>
                  C4A which registered under register company of Nanterre with number 812833655 and its registered
                  office at 45 boulevard Victor Hugo 92110 Clichy, France.
                </p>

                <h5>"Digital Assets"</h5>
                <p>
                  Means assets which exist in forms of tokens which can give an access to specific service, used as
                  means of payment, used in loyalty reward systems, represents traditional assets (ie: real-estate,
                  stocks, bonds) or Crypto Assets (ie: Bitcoin, Ethereum) which can be exchanged by using blockchain
                  technologies.
                </p>

                <h5>"Digital Assets Vault multi signature"</h5>
                <p>Means a multi-authorization cryptocurrency wallet.</p>

                <h5>"Device Application"</h5>
                <p>Means a software application, developed by Tozex or by third parties.</p>

                <h5>"Fork"</h5>
                <p>
                  Means a change to the underlying protocol of a blockchain network that results in more than one
                  version of a Crypto Asset, the result of which may be one or more versions that are not supported with
                  our Services.
                </p>

                <h5>"Gas"</h5>
                <p>
                  Gas refers to the fee required to successfully conduct a transaction or execute a Smart Contract on
                  the Ethereum blockchain network. Priced in sub-units of the cryptocurrency ether, known as gwei. In
                  other words, it’s the amount of ether (ETH) a user pays to perform a transaction on the Ethereum
                  blockchain network.
                </p>

                <h5>"Materials"</h5>
                <p>
                  Means content documentation as well as source and object codes for all software, module or APIs
                  embedded within the Platform.
                </p>

                <h5>"Platform"</h5>
                <p>
                  Means all the infrastructure, modules and services accessible though website{' '}
                  <a href="">www.tozex.io</a>
                </p>

                <h5>"Private Keys"</h5>
                <p>Means a critical piece of data used to authorize outgoing transactions on blockchain networks.</p>

                <h5>"Services"</h5>
                <p>Means one or more of the services available through the Website or Third Party Services.</p>

                <h5>"Smart Contract"</h5>
                <p>Code used as software agent acting autonomously according to specific predetermined rules.</p>

                <h5>"Third Patty Services"</h5>
                <p>
                  Refers to applications, softwares or other Materials that are hosted, developed or operated by a third
                  party, including, but not limited to: depositary escrow services, KYC/AML service provider, payment
                  service provider, cryptocurrency exchanges, auditor, legal service provider, or staking service
                  providers.
                </p>

                <h5>"Wallet"</h5>
                <p>
                  Means a software program which interacts with various blockchain networks to generate and manage sets
                  of private keys and public keys, configure transactions and monitor their balance.
                </p>

                <h5>"Website"</h5>
                <p>Means websites owned and operated by Tozex.</p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>TERMS ACCEPTANCE</h3>
                <p>
                  By creating an Account, you expressly agree to these Terms, Third Party Services Terms and the Privacy
                  Policy.
                </p>

                <p>
                  We may change, amend, delete or add to these Terms at all time and at our sole discretion, without
                  prior notice. By continuing to access the Platform, you are deemed to accept these changes. We
                  highlight the fact that we may make any changes to the Platform or the Services in our absolute
                  discretion and without any notification, without any liability.
                </p>

                <p>
                  You acknowledge that the Company and the Platform are not responsible for transferring, safeguarding,
                  or maintaining your private keys or any Digital Assets associated therewith. If you lose, mishandle or
                  have stolen associated Digital Assets private keys, you acknowledge that you may not be able to
                  recover associated Digital Assets, and that we are not responsible for such loss. You acknowledge that
                  the Company and the Platform are not responsible for any loss, damage or liability arising from your
                  failure to comply with the Terms.
                </p>

                <p>By accepting those Terms and creating an Account on the Platform, you represent and warrant that:</p>

                <ul style={{ listStyleType: 'disc', marginLeft: '15px' }}>
                  <li>You have read and understood and accepted these Terms;</li>
                  <li>
                    {' '}
                    You have the legal capacity to do so and are considered legally capable in your country of
                    citizenship and residence;
                  </li>
                  <li>
                    You agree and represent that you will use our Services for yourself and you may not use your account
                    to act as an intermediary or broker for any other third party, person or entity;
                  </li>
                  <li>You are full capacity to enter into a transaction involving Digital Assets & fiat currencies;</li>
                  <li>
                    {' '}
                    You are creating an Account for your own use and do not act as an intermediary, broker or trustee of
                    any kind for any person;
                  </li>
                  <li>
                    {' '}
                    You have verified that purchasing, use, or exchange of Digital Assets is legal in your country of
                    citizenship or residence or tax residence and in your personal situation;
                  </li>
                  <li>You have not previously been suspended or banned from the Platform;</li>
                  <li>
                    You will not use the Platform for any illegal activity, including but not limited to password theft,
                    network attacks (e.g. network attacks causing a loss of service), money laundering or the financing
                    of terrorism;
                  </li>
                  <li>
                    {' '}
                    You warrant that you are not in any list of trade or economic sanctions, such as the UN Security
                    Council Sanctions List. List of restricted areas may change at any time regarding evolution of the
                    international regulation.
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>ELIGIBILITY</h3>
                <p>To be eligible to use the Site, you must be at least 18 years old.</p>

                <p>
                  You hereby represent and warrant that you are fully able and competent to enter into the terms,
                  conditions, obligations, affirmations, representations and warranties set forth in these Terms and to
                  abide by and comply with these Terms.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>TERMINATION OF YOUR ACCOUNT</h3>
                <p>
                  Only one Account per user is authorized. In case of knowledge that you have created more than one
                  Account, we will immediately delete any double Account and only keep your original Account. In case of
                  violation of these Terms, your account can be closed without any prior notice.
                </p>

                <p>You agree to :</p>

                <ul style={{ listStyleType: 'disc', marginLeft: '15px' }}>
                  <li>never use the same password for your Account that you have ever used outside of the Platform,</li>
                  <li>keep your secret information and password confidential and do not share them with anyone else</li>
                  <li>immediately notify us of any unauthorized use of your account or breach of security.</li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>PRIVACY</h3>
                <p>
                  Your use of the Site is subject to the Privacy Policy as disclosed on the Site. Please review the
                  Privacy Policy, which also governs the Site and informs users of our data collection practices.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>ACCURACY OF INFORMATION</h3>
                <p>
                  You must provide accurate, authentic and up-to-date information, including, but not limited to, during
                  registration or for verification grounds.
                </p>

                <p>
                  You shall notify us any update in your information, and we will not be liable for any loss incurred by
                  outdated information.
                </p>

                <p>
                  You hereby acknowledge that we may verify your personal information either directly or indirectly
                  through Third Party, which you entitle to contact you directly. In any event, you commit to respond
                  fully to all inquiries and requests. If access the Site from a location outside Europe, you are
                  responsible for compliance with all local laws. You agree that you will not use the content accessed
                  through the Site in any country or in any manner prohibited by any applicable laws, restrictions, or
                  regulations.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>ACCESS TO SERVICES</h3>
                <p>
                  The Site and the Services are accessible 7 days a week and 24 hours a day, except for maintenance
                  operations or unavailability of the networks due to a cause not attributable to the Company.
                </p>

                <p>
                  We use our best efforts to maintain the availability of the Services. However, in the event of
                  unavailability of the Site, no compensation may be required by Consumers or Third Party Service
                  providers. In order to use the Services, you will need to register an account on the Platform through
                  our Site.
                </p>

                <p>
                  To use our Services you may need to fulfil certain legal obligations in your country and/or state of
                  residence. By accepting these Terms, you confirm that you have reviewed your local laws and
                  regulations and that you are aware of, and fulfil any and all such obligations. Due to legal or
                  regulatory prohibitions, we do not offer the use of our Services in certain jurisdictions. By
                  accepting the Terms, you confirm that you are not a resident or governed by the laws and regulations
                  of those jurisdictions.
                </p>

                <p>
                  <b>Your Account</b>
                </p>
                <p>
                  During the registration process, we will ask you for certain information, including but not limited
                  to, your name, address and other personal information to verify your identity. If you use the Platform
                  and/or Site, you are responsible for maintaining the confidentiality of your Account and password, and
                  for restricting access to your computer, and you agree to accept responsibility for all activities
                  that occur under your Account or password.
                </p>

                <p>
                  You may not assign or otherwise transfer your account to any other person or entity. You acknowledge
                  that the Company is not responsible for Third Party access to your account that results from theft or
                  misappropriation of your Account. Company and its associates reserve the right to refuse or cancel
                  service, terminate accounts, or remove or edit content in our sole discretion.
                </p>

                <p>
                  If you are using the Services on behalf of a legal entity such as a corporate entity, you further
                  represent and warrant that: (i) the legal entity is duly organized and validly existing under the
                  applicable laws of the jurisdiction of its organization; and (ii) you are duly authorized by such
                  legal entity to act on its behalf.
                </p>

                <p>
                  <b>Technical requirements</b>
                </p>
                <p>
                  For optimal use of the Site, we recommends using applications and browsers based on Web 3
                  architecture. Web 3 is an umbrella term for a set of emerging technologies intersecting
                  cryptocurrencies, blockchains and distributed systems that, together, extend the capabilities of the
                  web we all use today in important and meaningful ways. With computer and/or mobile device, we suggest
                  to use Google Chrome or Mozilla Firefox with the Metamask plugin activated or the crypto-wallet option
                  activated. We advise to validate terms of user from browsers providers, Company will not have any
                  liability regarding malfunction of those browsers.
                </p>

                <p>
                  <b>Ownership of your own Wallet</b>
                </p>
                <p>
                  In order to be able to effectively use the Services available through the Platform, you will need to
                  use your own Ethereum wallet. Private Key must never be communicated to a third party and nor even to
                  the Platform team. We will never ask your Private Keys.
                </p>

                <p>
                  For project holders/issuers, Ethereum public address is required to launch token based fundraising
                  crowdsale (ICO, STO, BRO). The Ethereum public address will be used to received the fund raised during
                  the campaign and sign all transactions. In case, the token based fundraising campaign is successful
                  raised funds in forms of Digital Assets, they will be transferred to the Ethereum public address used
                  for the publication of the token based fundraising campaign through dashboard. Project holders are
                  sole responsible for their Wallet and must imperatively keep access (private key and public key) to
                  these addresses, otherwise all of raised funds could be permanently lost.
                </p>

                <p>
                  For investors, Ethereum addresses allow to participate in various token based fundraising crowdsale
                  proposed by project holders, retrieve and store Digital Asset issued if the token based fundraising
                  crowdsale (ITO) is successful or recover the invested amount previously deposited in the event of the
                  ITO failure. The transfer of tokens or the reimbursement of contributions will be made on the same
                  Ethereum address as that has been used to make the investment. Project holders/issuers are solely
                  responsible for the entire terms and conditions of the project implementation with respect to the
                  investors and in particular of the commitments specified in the whitepaper. Therefore, stakeholders
                  must contact directly the project holder/issuer for any questions and/or claims related to the
                  project.
                  <b>
                    We remind you that regarding decentralized infrastructure characteristics of the Platform which
                    allows to match directly project holders and investors, Tozex will not be liable for any trouble
                    resulting from relationship between both parties. In any case and any circumstances Tozex will be
                    considered as an intermediary between both parties.
                  </b>
                </p>

                <p>
                  <b>AML/KYC verification</b>
                </p>
                <p>
                  The Platform allows the realization of transactions in various Crypto Assets such as Ether (ETH) or
                  DAI and is not responsible of the conversion rate between Crypo Assets themselves and/or fiat
                  currencies. Conversions are the sole responsibility of investors and project holders/issuers.
                </p>

                <p>
                  We are particularly sensitive and involved in the fight against money laundering and the financing of
                  terrorism. In accordance with our AML compliance program and procedures, we are required to identify
                  all Customers on our Platform. This ensures we remain in compliance with Know-Your- Customer (“KYC”)
                  and AML laws in the jurisdictions in which we operate, and reduces the chance that the TOZEX Services
                  will be misused for criminal purposes.
                </p>

                <p>
                  Tozex and/or Third Party Service provider collects and verifies information about you in order to: (a)
                  protect the company and the community from fraudulent Users, and (b) to keep appropriate records of
                  Tozex customers. Your daily or weekly purchasing and sale limits, fiat currency transfer limits, and
                  limits on transactions from a linked payment method are based on the identifying information and/or
                  proof of identity you provide to Tozex and/or Third Party Service providers which proceed to KYC/AML
                  verification.
                </p>

                <p>
                  The KYC validation process is done directly on the Site through an application programming interface
                  (API) provided by a Third Party Service provider. The KYC check consists of verifying that a natural
                  person is not on a list of persons under surveillance as a result of offenses related to money
                  laundering, terrorist acts or political acts.
                </p>

                <p>
                  All customers who wish to use our Services are required to establish provide various information and
                  documents :
                </p>

                <ul style={{ listStyleType: 'circle', marginLeft: '15px' }}>
                  <li>Providing your name and valid email address, a password and your state of residence,</li>
                  <li>Certifying that you are 18 years or older and able to enter into this Platform,</li>
                  <li> Accepting those Terms and Privacy Policy, and</li>
                  <li>Verifying your identity by submitting the following information:</li>
                  <li className="list-style-none">
                    <ul style={{ listStyleType: 'disc', marginLeft: '15px' }}>
                      <li>Name</li>
                      <li>Date of birth</li>
                      <li>Email address</li>
                      <li>Mobile Device number</li>
                      <li>Physical street address</li>
                      <li>Social security number or taxpayer identification number</li>
                      <li>
                        A copy of an acceptable form of identification (i.e., unexpired passport, state driver’s
                        license, or state identification card), and
                      </li>
                      <li>A picture of yourself or a selfie from your webcam or mobile phone.</li>
                    </ul>
                  </li>
                </ul>

                <p>
                  The KYC procedure aims to verify that Customer is not on a list of persons under surveillance as a
                  result of offenses related to terrorist acts, money laundering or political acts. Depending on the
                  kind of account, the control is carried out by verification of the identity of the Customer based on
                  name, verified phone number, proof of address, identity verification with face match with webcam or
                  mobile phone. For legal persons, control is carried out based on the extract from register of company,
                  the company's articles of association and the identity of the director.
                </p>

                <p>
                  Notwithstanding these minimum verification procedures for the referenced Services, Tozex and/or Third
                  Party Services provider may require from you to provide or verify additional information, or to wait
                  some amount of time after completion of a transaction, before permitting you to use any Services
                  and/or before permitting you to engage in transactions beyond certain volume limits. It could be
                  required from you to submit to Enhanced Due Diligence. Additional fees and costs may apply.
                </p>

                <p>
                  <b>Supported Digital Assets</b>
                </p>
                <p>We shall have the sole and absolute discretion to :</p>
                <ul style={{ listStyleType: 'disc', marginLeft: '15px' }}>
                  <li>
                    determine and allow the buying and selling of any Digital Assets issued, managed, distributed or
                    exchanged by using the Services; or
                  </li>
                  <li>
                    delist, suspend or remove any Digital Assets from being transferred, bought or sold using the
                    Services.
                  </li>
                </ul>

                <p>
                  <b>Promotion of the project</b>
                </p>
                <p>
                  Platform will use all reasonable means, on best effort basis, to allow project to benefit from the
                  audience of the Site and relay the project towards the community of users. The project holder/issuer
                  is free to carry out the communication and marketing operations he wishes for his project by using all
                  channels and means he wants. Platform will offer the possibility to the project holder to benefit from
                  additional communication and/or marketing services proposed by a Third Party Service Provider or
                  Partner of the Platform as the price agreed between them.
                </p>

                <p>
                  <b>Links to Third Party Sites/Third Patty Services</b>
                </p>
                <p>
                  The Site may contain links to third party websites ("Linked Sites"). The Linked Sites are not under
                  the control of Tozex which is not responsible for the contents of any Linked Site, including without
                  limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Tozex is
                  providing these links to you only as a convenience, and the inclusion of any link does not imply
                  endorsement by Tozex of the site or any association with its operators.
                </p>

                <p>
                  Certain services made available via the Site may be delivered by third party sites and organizations.
                  By using any third-party product, service or functionality originating from the Site, you hereby
                  acknowledge and consent that Tozex may share such information and data with any third party with whom
                  Tozex has a contractual relationship to provide the requested product, service, or functionality on
                  behalf of Site users and customers.
                </p>

                <p>
                  Tozex has no control over, or liability for, the delivery, quality, safety, legality, or any other
                  aspect of any goods, Digital Assets or services that you may purchase or sell to or from a third party
                  (including other users of Tozex Services). Tozex is not responsible for ensuring that a buyer or a
                  seller you may transact with will actually complete the transaction, or is authorized to do so. If you
                  experience a problem with any goods or services purchased from, or sold to, a third party in
                  connection with digital assets transferred using the Tozex services, or if you have a dispute with
                  such third party, you must resolve the dispute directly with that third party. If you believe a third
                  party has behaved in a fraudulent, misleading, or inappropriate manner, or if you cannot adequately
                  resolve a dispute with a third party, you may notify Tozex support@tozex.io so that we may consider
                  what action to take, if any.
                </p>

                <p>
                  <b>Third Party accounts</b>
                </p>
                <p>
                  You may be able to connect your Account to third-party accounts. By connecting your Tozex Account to
                  your third-party account, you acknowledge and agree that you are consenting to the continuous release
                  of information about you to others (in accordance with your privacy settings on those third-party
                  sites). If you do not want information about you to be shared in this manner, do not use this feature.
                </p>

                <p>
                  <b>Third Party Payments</b>
                </p>
                <p>
                  Services are made available to you solely for the purposes of facilitating Digital Assets issuance,
                  management and exchange. Tozex has no control over, or liability for, the delivery, quality, safety,
                  legality or any other aspect of any Digital Assets or services that you may purchase from or sell to a
                  third party (including Third Party Services providers). Tozex is not responsible for ensuring that a
                  buyer or a seller you may transact with will actually complete the transaction or is authorized to do
                  so. If you experience a problem with any goods or services purchased from, or sold to, a third party
                  in connection with Digital Assets transferred using the Services, or if you have a dispute with such
                  third party, you must resolve the dispute directly with that third party.
                </p>

                <p>
                  <b>Discontinuance of Services</b>
                </p>
                <p>
                  We may, in our sole discretion and without cost to you, with or without prior notice, and at any time,
                  modify or discontinue, temporarily or permanently, any portion of our Services. You are responsible
                  for storing outside of the Platform your back up phrase and/or mnemonic passphrase. Saving your backup
                  phrase and private keys pairs associated with your Wallet associated with your Account will allow you
                  to access the blockchain and your funds at any place and time. This backup will allow you to fully
                  restore your Wallet at any time without cost or loss of the Digital Assets.
                </p>

                <p>
                  Tozex shall not be held responsible or liable for any loss of Digital Assts in the event that we
                  discontinue or deprecate the Services.
                </p>

                <p>
                  <b>No Unlawful or Prohibited Use/Intellectual Property</b>
                </p>
                <p>
                  pilation thereof, and any software/hardware used on the Site, is the property of Tozex or its
                  suppliers and protected by copyright and other laws that protect intellectual property and proprietary
                  rights. You agree to observe and abide by all copyright and other proprietary notices, legends, or
                  other restrictions contained in any such content and will not make any changes thereto.
                </p>

                <p>
                  You are granted a non-exclusive, non-transferable, revocable license to access and use the Site
                  strictly in accordance with these Terms. As a condition of your use of the Site, you warrant to Tozex
                  that you will not use the Site for any purpose that is unlawful or otherwise prohibited by these
                  Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the
                  Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt
                  to obtain any materials or information through any means not intentionally made available or provided
                  for through the Site.
                </p>

                <p>
                  You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
                  derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
                  Tozex content is not for resale. Your use of the Site does not entitle you to make any unauthorized
                  use of any protected content, and in particular you will not delete or alter any proprietary rights or
                  attribution notices in any content. You will use protected content solely for your personal use, and
                  will make no other use of the content without the express written permission of Tozex and the
                  copyright owner. You agree that you do not acquire any ownership rights in any protected content. We
                  do not grant you any licenses, express or implied, to the intellectual property of Tozex or our
                  licensors except as expressly authorized by these Terms or into specific agreement.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>RELEASE OF TOZEX & INDEMNITY</h3>
                <p>
                  If you have a dispute with one or more users of our Services, you release the Company, its affiliates
                  and service providers, and each of its or their respective officers, directors, employees, agents and
                  representatives, from any and all claims, demands and damages (actual and consequential) of every kind
                  and nature arising out of or in any way connected with such disputes. You agree to indemnify and hold
                  the Company, its affiliates and each of its or their respective officers, directors, employees, agents
                  and representatives, harmless from any claim or demand (including attorneys’ fees and any fines, fees
                  or penalties imposed by any regulatory authority) arising out of or related to your breach of this
                  Terms or your violation of any law, rule or regulation, or the rights of any third party.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>FEES & COMMISSION PAID BY CUSTOMERS</h3>
                <p>
                  <b>Blockchain transcation fees</b>
                </p>
                <p>
                  It’s well know that, owner don’t physically own a cryptocurrency like Bitcoin. Instead, the blockchain
                  records ownership and distributes these ownerships information to the whole network. Indeed, the
                  Bitcoin Blockchain network is able to verify transactions without a single authority because of miners
                  who allocate their computing power to the network. Fees are necessary in order to proceed a
                  transaction, get it verified and included in the next block.
                </p>

                <p>
                  The actual amount of fees you pay depends on the crypto assets and the Blockchain network Customers
                  have selected. Customer will have to pay those fees. On Ethereum Blockchain, every transaction implies
                  to pay a Gas price which depends on the size and complexity of the transaction. Basically, Gas is the
                  internal pricing for running a transaction in Ethereum Blockchain. The exact price of Gas is
                  determined by the network's miners, who can decline to process a transaction if the Gas price does not
                  meet their threshold. We invite Customers to check Gas price in real time by using{' '}
                  <a href="">gas tracker tool on Etherscan.io</a> before proceeding a transaction to be sure that they
                  have enough ETH to paid Gas price more particularly in case of congestion of network.
                </p>

                <p>
                  <b>Third Party Service provider fees</b>
                </p>
                <p>
                  In case Customer has to use a Third Party Service, he will need to paid fees requested by the service
                  provider according to terms & conditions of the Third Party Service provider. Additionally, some
                  Credit/Debit Card issuers and some banks may charge additional fees to their account holders. Please
                  check with your financial institution regarding such fees.
                </p>

                <p>
                  <b>Platform commission</b>
                </p>
                <p>
                  Platform charges fees for Services, applicable fees will be displayed prior to you using any Service
                  to which a fee applies. All Services available are subject to current price and modalities disclosed
                  on the Site. Our fees are subject to change and Tozex reserves the right to adjust its pricing and
                  fees and at any time.
                </p>

                <p>
                  <b>Reversals & cancellations</b>
                </p>
                <p>
                  Unfortunately, Customer cannot cancel or reverse Digital Assets transactions thanks to intrinsic
                  characteristics of Blockchain network. Indeed, transactions on the Blockchain network are designed to
                  be irreversible and we have no control over them. Customer cannot cancel, reverse or change any
                  completed or pending Digital Asset transaction once it is submitted.
                </p>

                <p>
                  If you use a Service to which a commission applies, or you initiate a transaction with a Gas price via
                  the Services, you will not be eligible for a refund or reimbursement once you have confirmed that you
                  wish to proceed with the Service or transaction.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>JURISDICTION & DISPUTE</h3>
                <p>
                  This Terms and your use of the Site and Services shall be governed by and construed in accordance with
                  the French law, without regard to principles of conflict of laws.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>FEEDBACK AND USER SUBMISSIONS</h3>
                <p>
                  Tozex is always seeking to improve its Services and customers experience in order to reach their
                  expectation. If you have ideas or suggestions regarding improvements or additions to our Services or
                  the Site, we would be more than happy to take a close attention on your suggestion. However, any
                  submission will be subject to the Terms.
                </p>

                <p>
                  Under no circumstances will any disclosure of any idea or feedback, or any related material to Tozex
                  or any of its subsidiaries, parents or affiliated companies, or any of their officers, directors,
                  managers, CUSTOMERs, shareholders, employees and agents, or any of their heirs, successors,
                  representatives and assigns (each a “Tozex Party” and collectively, the (“Tozex Parties”) be subject
                  to any obligation of confidentiality or expectation of compensation.
                </p>

                <p>
                  By submitting an idea or feedback or any related material that would be subject to intellectual
                  property rights (the “Work”) to Tozex Party, you grant us, in respect of the Work submitted, a
                  non-exclusive, perpetual, worldwide royalty free license to use all of the content of such ideas and
                  feedback, for any purpose whatsoever
                </p>

                <p>
                  You further accept that Tozex could use in any way any Work and material you have submitted. We have
                  the right to remove any posting you may make to the Website, in our absolute discretion, without
                  warning or reasons.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>RISKS FACTORS</h3>

                <p>
                  <b>
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES WHICH INCLUDES THIRD PARTY SERVICES
                    IS AT YOUR SOLE RISK.
                  </b>
                </p>

                <p>
                  Should carefully consider and evaluate each of the following risk factors before deciding to use all
                  Services available on Platform such as participate in a token based fundraising crowdsale propose by
                  project holder/issuer. Prospective users (issuer, investor, trader, third party) should give careful
                  consideration to the following risk factors in evaluating the merits and suitability of an investment.
                </p>

                <p>
                  The following does not purport to be a comprehensive summary of all of the risks associated with an
                  investment in Digital Assets available. Prospective users should discuss a potential investment with
                  their professional advisors. No one should purchase Digital Assets who is not prepared to lose the
                  entirety of his, her or its investment. By using Services you expressly acknowledges understand and
                  assume the following risks:
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>GENERAL RISKS</h3>
                <p>
                  <b>Private Keys loss</b>
                </p>
                <p>
                  You acknowledge that Tozex is not responsible for transferring, safeguarding, or maintaining your
                  private keys or any Digital Assets associated therewith. If you lose, mishandle or have stolen
                  associated Digital Assets private keys, you acknowledge that you may not be able to recover associated
                  Digital Assets, and that we are not responsible for such loss. You acknowledge that Tozex is not
                  responsible for any loss, damage or liability arising from your failure to comply with the terms
                  hereunder.
                </p>

                <p>
                  <b>Highly speculative investment risk</b>
                </p>
                <p>
                  Digital Assets are highly speculative and highly risky, and present the risk of total loss of the
                  invested capital. Moreover, the fact that there exists a certain opacity in the cryptocurrency market,
                  as well as the fact that regulation to date is very limited if any means that there exists certain
                  risks of manipulation with insider trading.
                </p>

                <p>
                  <b>Volatility risk</b>
                </p>
                <p>
                  Digital Assets are subject to large price fluctuations in both directions. The amplitude of such
                  fluctuations is difficult to predict.
                </p>

                <p>
                  <b>Risk of dissolution of the project holder's company</b>
                </p>
                <p>
                  It is possible that, due to any number of reasons, including, but not limited to, an unfavourable
                  fluctuation in the value of the Digital Assets created by project holder (or other cryptographic and
                  fiat currencies), due to negative adoption of the Digital Assets, solution developed or the company
                  itself, the failure of commercial relationships, or intellectual property ownership challenges, the
                  project holder’s company may no longer be viable to operate and the latest company may dissolve.
                </p>

                <p>
                  <b>Risks related to markets and other uncertain events</b>
                </p>
                <p>
                  Digital Assets investments may suffer significant losses in case of disruption of conventional markets
                  or markets of cryptocurrencies/Digital Assets in general. Other more or less extraordinary and/or
                  unpredictable events may cause a rupture with historical prices of some Digital Assets. Any failure of
                  communications systems and disruption of the decentralized network also present a significant risk.
                </p>

                <p>
                  <b>Counterparty risk</b>
                </p>
                <p>
                  Investor is exposed to a counterparty risk when entering into a transaction with the project holder’s
                  company. In particular, if the latest company is not able to meet its obligations. In this context,
                  the investor is exposed to the risks of failure including bankruptcy faced by a counterparty of the
                  project holder’s company which may cause the impossibility for the latest to deliver Digital Assets
                  despite payment in advance from the investor.
                </p>

                <p>
                  The risk exists that the Issuer may fail to meet their (financial) obligations to the Investors in
                  whole or in part, or fail to do so in a timely manner. The Issuer bears full insolvency and default
                  risk with respect to its counterparties. No collateral has been provided to the benefit of the Issuer
                </p>

                <p>
                  <b>Exchange rate or exchange rate risk</b>
                </p>
                <p>
                  The Customer will use various cryptocurrencies (XLM, ETH, BTC, EURT) and fiat currencies (USD, EUR,
                  etc.). Digital Assets more specifically Crypto Assets are often subject to high exchange rate
                  fluctuations. During the respective exchange processes, the Issuer and Investors may incur currency or
                  exchange rate losses. Tozex and Third Party Services providers have no control over market price
                  fluctuations.
                </p>

                <p>
                  <b>Force Majeure</b>
                </p>
                <p>
                  Tozex shall not be liable for delays, failure in performance or interruption of service which result
                  directly or indirectly from any cause or condition beyond Tozex reasonable control, including but not
                  limited to, any delay or failure due to any act of God, act of civil or military authorities, act of
                  terrorists, civil disturbance, war, pandemic situation, strike or other labour dispute, fire,
                  interruption in telecommunications or Internet services or network provider services, failure of
                  equipment and/or software, other catastrophe or any other occurrence which is beyond our reasonable
                  control and shall not affect the validity and enforceability of any remaining provisions.
                </p>

                <p>
                  <b>English Language controls</b>
                </p>
                <p>
                  Notwithstanding any other provision of this Terms, any translation of this Terms is provided for your
                  convenience. The meanings of terms, conditions and representations herein are subject to definitions
                  and interpretations in the English language. Any translation provided may not accurately represent the
                  information in the original English.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>TECHNOLOGICAL & SECURITY RISKS</h3>
                <p>
                  <b>Risks associating with development of new technology</b>
                </p>
                <p>
                  The platform will use a new technology. There are no guarantees that such technology will be bug- free
                  or accepted by the marketplace. Thus, even should the Platform become operational, Digital Assets may
                  be subject to the risk of theft, loss, malfunction, or reputational risk, any of which can
                  significantly degrade the value of such instrument.
                </p>

                <p>
                  <b>Risks associated with blockchain technology</b>
                </p>
                <p>
                  Customer (issuer, investor, trader or third party) uses blockchain technology in various ways.
                  Blockchain technology is a relatively young and little rehearsed and tested technology. Cusotmer bears
                  the risk that this technology may be subject to technical difficulties or that its functionality may
                  be impaired by external influences. A partial or complete collapse of a blockchain or one or more
                  cryptocurrencies would make it impossible to implement the business model.
                </p>

                <p>
                  <b>Risks associated with the Ethereum protocol</b>
                </p>
                <p>
                  Digital Assets created by project holders/issuers by using the Platform are based on the Ethereum
                  protocol, any malfunction, breakdown or abandonment of the Ethereum protocol may have a material
                  adverse effect on the project or Digital Assets and their value. Moreover, advances in cryptography,
                  or technical advances such as the development of quantum computing, could present risks to the Digital
                  Assets and the Platform by rendering ineffective the cryptographic consensus mechanism that underpins
                  the Ethereum protocol. Smart contract concepts, the underlying software application and software
                  platform (i.e. the Ethereum blockchain) is still in an early development stage and unproven. There is
                  no warranty or assurance that the process for creating Digital Assets will be uninterrupted or
                  error-free and there is an inherent risk that the Smart Contract could contain defects, weaknesses,
                  vulnerabilities, viruses or bugs causing, amongst other things, the complete loss of Digital Assets.
                </p>

                <p>
                  <b>Risk of transactions related to the blockchain</b>
                </p>
                <p>
                  Most cryptocurrencies are based on the technology of the Blockchain. This technology is not adapted to
                  the increasingly high volume of transactions of certain Crypto Assets. Thus, there is a high risk of
                  delays or blockage in the execution of certain transactions.
                </p>

                <p>
                  <b>Risks related to the private keys and cybersecurity</b>
                </p>
                <p>
                  Anyone who holds Private Keys can thus dispose of it at his sole discretion. In this context, the
                  theft, loss or hacking of Private Keys means the permanent loss of Digital Assets. Therefore, the
                  customer (issuer, investor, trader or any Third Party) is well advised to (i) keep their Private Keys
                  in a safe location, offline, and (ii) regularly update the security of his computer systems.
                </p>

                <p>
                  <b>Risks related to the Wallet</b>
                </p>
                <p>
                  The wallet used by the customer (issuer, investor, trader) must be compatible with our Services and
                  technical specificities of the Digital Asset. If Digital Asset are transferred to an incompatible
                  wallet, normally the customer will no longer be able to access and dispose of the Digital Assets. This
                  will means a total loss of its investment from investor point of view. The customer bears full
                  responsibility regarding the decision on the correct (compatible) wallet. The investor alone is also
                  responsible for the secure storage of the private key of its wallet necessary to receive and dispose
                  of tokens. The loss or theft of the Private Key is equivalent to the loss of all Digital Assets
                  assigned to the wallet.
                </p>

                <p>
                  <b>Cybersecurity risks</b>
                </p>
                <p>
                  Tozex utilizes a substantial amount of electronic information. This includes transaction information
                  and sensitive personal information. The Third Party Services providers used by Tozex, may also use,
                  store, and transmit such information. Tozex intends to implement detailed Cybersecurity policies and
                  procedures and an incident response plan designed to protect such information and prevent data loss
                  and security breaches. However, such measures cannot provide absolute security. Breach of the
                  information systems may cause information relating to the transactions of Tozex and sensitive.
                </p>

                <p>
                  <b>Risk of DDoS attacks</b>
                </p>
                <p>
                  By a so-called Distributed Denial of Service (DDoS) attacks, attackers can overload a network or a
                  blockchain with a high number of requests and/or transactions and (temporarily) render the network or
                  the corresponding blockchain unusable. For example, the maximum number of transactions per second on
                  the Stellar Blockchain used by the issuer is currently approx. 1,000/sec. If a critical transaction
                  number is exceeded for a longer period of time due to a DDoS attack, Digital Assets holders would not
                  be able to receive payments or transfer their Digial Assets.
                </p>

                <p>
                  <b>Risk of alternaitive networks</b>
                </p>
                <p>
                  It is possible that alternative networks could be established in an attempt to facilitate services.
                  Platform may compete with these alternative networks, which could negatively impact the Platform, the
                  TOZ Token and its value.
                </p>

                <p>
                  <b>Risk of hard fork and token swap</b>
                </p>
                <p>
                  The Platform will need to go through substantial development works as part of which it may become the
                  subject of significant conceptual, technical and commercial changes. As part of the development, an
                  upgrade to the TOZ Token may be required (hardfork of token) or swap into a new one and if the holders
                  decide not to participate in such event, holder may no longer be able to use TOZ Tokens and any
                  non-upgraded tokens may lose their functionality in full.
                </p>

                <p>
                  <b>Risk of infected device</b>
                </p>
                <p>
                  We shall not bear any liability, whatsoever, for any damage or interruptions caused by any computer
                  viruses, spyware, scareware, Trojan horses, worms, or other malware that may affect your computer or
                  other equipment, or any phishing, spoofing or other attack. We advise the regular use of a reputable
                  and readily available virus screening and prevention software. You should also be aware that SMS and
                  email services are vulnerable to spoofing and phishing attacks and you should use care in reviewing
                  messages purporting to originate from Tozex. Always log into directly on your Account to review any
                  transactions or required actions if you have any uncertainty regarding the authenticity of any
                  communication or notice.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>LEGAL AND TAX RISKS</h3>
                <p>
                  <b>Regulatory risks</b>
                </p>
                <p>
                  Any future regulation, whether European or foreign, in the cryptocurrency market can lead to a
                  limitation of the possibility to hold and/or exchange of Digital Assets which can have a significant
                  impact on the price and liquidity of them. The extent and type of the future changes in the regulatory
                  environment as well as the impact of additional regulations are unknown and difficult to predict.
                </p>

                <p>
                  <b>Tax considerations</b>
                </p>
                <p>
                  There is no established practice regarding the taxation of gains on cryptocurrencies whether in
                  Switzerland or abroad. Moreover, existing practices may fluctuate. It is the responsibility of the
                  Client to seek relevant tax advice in order to measure the tax impact of contemplated transactions and
                  comply with its tax obligations.
                </p>

                <p>
                  <b>Risk of criminal offences</b>
                </p>
                <p>
                  Due to the system, token based offerings are subject to increased susceptibility to fraud, money
                  laundering and terrorist financing. This increases the investor's risk of losing the capital it has
                  invested, also due to necessary measures taken by the authorities against the operators or other
                  persons involved in such illegal transactions.
                </p>

                <p>
                  <b>Qualified advice</b>
                </p>
                <p>
                  The information contained in official documentation related to a token based offerings such as
                  whitepaper or prospectus, does not replace any qualified advice that may be required from a third
                  party. An investment decision should not be made solely on the basis of the information in the
                  documentation or in those Terms, as the information contained herein cannot replace advice and
                  information tailored to the needs, objectives, experience and knowledge and circumstances of the
                  individual investor. Otherwise, there is a risk that the investor may acquire an investment that is
                  unsuitable for him.
                </p>

                <p>
                  <b>Unanticiped risks</b>
                </p>
                <p>
                  Digital Assets are based on a young technology. In addition to the risks included in this section,
                  there are other risks associated with purchase, holding, safeguard and use of Digital Assets,
                  including those that Tozex cannot anticipate.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 post-content">
                <h3>LIMITATION OF LIABILITY</h3>
                <p>
                  OUR SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES, REPRESENTATION OR
                  GUARANTEE, WHETHER EXPRESSED, IMPLIED OR STATUTORY.
                </p>

                <p>
                  THE COMPANY, DUE TO ITS DECENTRALIZED INFRASTRUCTURE CHARACTERISTICS AND AS MARKETPLACE WHICH MATCH
                  DIRECTLY PROJECT HOLDERS AND INVESTORS, WILL NOT BE LIABLE, EVEN PARTIALLY, IN CASE OF ACCURANCE OF AN
                  EVENT WHICH HAS IMPACT ON GOOD PROGRESS OF THE PROJECT, DELAYS OR CANCELLATION OF PROJECTS BY THE
                  PROJECT HOLDERS/ISSUER, THE FAILURE TO REALIZE THE PROJECT AS SOON AS THE OBJECTIVE OF FUNDRAISING IS
                  REACHED, OR STILL NON-PAYMENT OF THE COUNTERPARTMENTS PROMISED BY THE PROJECT HOLDERS/ISSUERS TO
                  INVESTORS, PARTNERS, TEAM OR ANY THIRD PARTY.
                </p>

                <p>
                  PROJECT HOLDERS/ISSUERS ARE SOLELY RESPONSIBLE FOR THE ENTIRE TERMS AND CONDITIONS OF THE PROJECT
                  IMPLEMENTATION WITH RESPECT TO THE INVESTORS AND IN PARTICULAR OF THE COMMITMENTS SPECIFIED IN THE
                  WHITEPAPER. THEREFORE, STAKEHOLDERS MUST CONTACT DIRECTLY THE PROJECT HOLDER/ISSUER FOR ANY QUESTIONS
                  AND/OR CLAIMS RELATED TO THE PROJECT. MOREOVER, IN CASE PROJECT HOLDER PUBLISH EXTERNAL LINKS ON THE
                  OFFICIAL WEBSITE OF TOZEX, THE RESPONSIBILITY OF THE COMPANY CANNOT BE SEARCHED IN ANY WAY ON THE
                  BASIS OF A HYPERTEXT LINK REFERRING TO A THIRD PARTY WEBSITE. INDEED, THE COMPANY WILL NOT EXERCISE
                  ANY CONTROL ON EXTERNAL LINKS AND THEIR CONTENT.
                </p>

                <p>
                  PROJECT HOLDERS/ISSUERS, INVESTORS AND ANY USERS OF THE PLATFORM GUARANTEES THE COMPANY AGAINST ANY
                  RECOURSE AND ANY DAMAGE MADE TO IT AGAINST A CUSTOMER, A VISITOR OR ANY OTHER PERSON BECAUSE OF THE
                  PRESENTATION, CONDUCT OR IMPLEMENTATION OF A PROJECT PUBLISHED ON THE SITE.
                </p>

                <p>AT ANY MOMENT AND IN ANY CIRCUMSTANCES, THE COMPANY :</p>

                <ul style={{ listStyleType: 'disc', marginLeft: '15px' }}>
                  <li>
                    {' '}
                    DOESN’T HAVE ANY ACCESS TO FUNDS AND DIGITAL ASSETS WHICH HAS BEEN RAISED DURING TOKEN BASED
                    OFFERING,
                  </li>
                  <li>DOESN’T HAVE ANY ACCESS TO DIGITAL ASSETS STORED ON WALLET OF ALL USERS,</li>
                  <li>DOESN’T ACT AS AN ESCROW SERVICE PROVIDER OR DEPOSITARY,</li>
                  <li>DOESN’ACT AS AN INTERMEDIARY BETWEEN SELLERS AND BUYERS OR BE QUALIFIED AS BROKER/DEALER,</li>
                  <li> DOESN’ACT AS CROWDFUNDING PLATFORM.</li>
                </ul>

                <p>
                  THE COMPANY PROVIDES SMART CONTRACT LIBRARY WHICH COULD BE USED FOR ALL SERVICES PROVIDED SUCH AS
                  CREATION & MANAGEMENT OF DIGITAL ASSETS IN FORM OF ERC20/721/1400 TOKENS BASED ON ETHEREUM BLOCKCHAIN
                  OR OTHER KIND OF TOKENS BASED ON OTHER KIND OF BLOCKCHAIN SYSTEM SUCH AS ROOTSTOCK, CREDITS, STELLAR,
                  VECHAIN OR WAVES. IN ANY CIRCUMSTANCES THE COMPANY COULD BE ASSIMILATED TO AN ISSUER OF DIGITAL
                  ASSETS.
                </p>

                <p>
                  PROJECT HOLDERS/ISSUER ARE ONLY OWNER OF THE SMART CONTRACT USED AND HAS FULLY ACCEPT ALL CODE WRITTEN
                  ON IT AND ITS RESPONSIBILITY RELATED. PROJECT HOLDER AND/OR INVESTOR GUARANTEES THE COMPANY AGAINST
                  ANY RECOURSE AND ANY DAMAGE MADE CAUSE BY SMART CONTRACT BREACH OR FAILURE AGAINST A CUSTOMER, A
                  VISITOR OR ANY OTHER PERSON.
                </p>

                <p>
                  CONSIDERING INTO THE FACT THAT THE COMPANY HAS TECHNOLOGICAL AGNOSTIC APPROACH, PROJECT
                  HOLDERS/ISSUERS WILL HAVE A CHOICE TO SELECT THE BLOCKCHAIN NETWORK OF THEIR CHOICE BETWEEN LIST OF
                  BLOCKCHAIN NETWORK SUCH AS ETHEREUM BLOCKCHAIN. THE COMPANY HAS NO INFLUENCE AND CONTROL ON BLOCKCHAIN
                  NETWORKS
                </p>

                <p>
                  CUSTOMERS AGREE THAT THE COMPANY DOES NOT PROVIDE ANY FINANCIAL ADVISORY TO THEM. LIABITLITY OF THE
                  COMPANY CANNOT BE ENGAGED ON RELATED TO THE CONSEQUENCES OF THE INVESTMENT RECEIVED FROM INVESTORS.
                  MORE PARTICULARLY, THE COMPANY PROVIDES NO RECOMMENDATIONS AND NO ADVICE ON THE TAX CONSEQUENCES FOR
                  INVESTORS AND PROJECT LEADERS. THUS, AND IN ACCORDANCE WITH THE PREVIOUS SUB-ARTICLE, IT IS FOR EACH
                  CUSTOMER TO LEARN MORE ABOUT THE TAX OBLIGATIONS RESULTING TO HIM BY RESPECT OF THE INVESTMENT OR
                  FUNDRAISING. THE COMPANY SHALL HAVE NO DUTY TO ADVISE IN RESPECT OF INVESTORS IN THE AMOUNTS OF THE
                  CONTRIBUTIONS INVESTORS MADE. THEREFORE, THE RESPONSIBILITY OF THE COMPANY CANNOT IN ANY CASE BE
                  SEARCHED ON THIS BASIS.
                </p>

                <p>
                  OTHERWISE, THE PLATFORM COULD MAKES AVAILABLE SERVICES PROVIDER PARTNERS SUITABLE FOR PROVIDING
                  FINANCIAL INGIENIERING, COMMUNICATION STRATEGY, MARKETING SERVICES, LEGAL ADVICE & OTHER KIND OF
                  ADVICE. IN THIS CASE, CUSTOMERS AND PARTNERS OF THE PLATFORM WILL DIRECTLY MAKES CONTRACT BETWEEN
                  THEM. THIS CONTRACT WILL SETTLE INDEPENDENTLY FROM THE COMPANY. CUSTOMERS REMAINS FREE TO USE ANY
                  PROVIDER OF THEIR CHOICE.
                </p>

                <p>
                  THE LIABILITY OF THE COMPANY AND/OR THE PLATFORM CANNOT BE TAKEN IN ANY WAY ON THIS BASIS. IN
                  ADDITION, THE CUSTOMER IS IN NO EVENT CONTAINED BY THE LIST OF PARTNERS PROPOSED BY THE COMPANY AND
                  REMAINS FREE TO USE ANY PROVIDER OF THEIR CHOICE. THE COMPANY IS SUBJECT TO THE LIMITS AND
                  CHARACTERISTICS OF TELECOMMUNICATIONS NETWORKS AND SERVICES, AND CANNOT THEREFORE BE HELD RESPONSIBLE
                  FOR MALFUNCTIONS RELATED TO THE COVER OF THE INTERNET NETWORK OR THE MOBILE TERMINAL INTERRUPTIONS OF
                  THE SITE AND / OR THE SERVICES FOR ANY DURATION OR FOR ANY REASON.
                </p>

                <p>
                  THE RESPONSIBILITY OF THE COMPANY CANNOT BE FURTHER ENGAGED IN THE EVENT OF THE DELETION OF SERVICES
                  WHICH IS ITS SOLE DISCRETION, OR A CHANGE OF LEGISLATION MAKING THE SERVICES INACCESSIBLE IN WHOLE OR
                  IN PART
                </p>

                <p>
                  THE CUSTOMER IS SOLELY RESPONSIBLE FOR ITS WALLET ADDRESS AND IN PARTICULAR FOR ITS PRIVATE KEY. TO
                  THE EXTENT THAT A CONTRIBUTION IS AFFECTED TO AN ADDRESS, IN THE HYPOTHESIS IN WHICH THE CUSTOMER
                  LOSES THE PRIVATE KEY OF ITS ADDRESS, IT ASSUMES ONLY THE CONSEQUENCES. THEREFORE, IT CANNOT IN ANY
                  CIRCUMSTURNS AGAINST THE COMPANY IF IT CANNOT, AS A RESULT OF THIS LOSS, RECOVER THE RESERVED DIGITAL
                  ASSETS FROM THIS ADDRESS OR PROCESS THE REPAYMENT OF THE CONTRIBUTIONS THAT IT MADE FROM THIS ADDRESS.
                  THEREFORE, THE COMPANY EXPRESSLY EXCLUDES ITS RESPONSIBILITY DUE TO ANY INDIRECT DAMAGE OR ANY
                  CONSEQUENTIAL DAMAGE SUFFERED BY A CUSTOMER OR VISITOR SUCH AS, IN PARTICULAR, LOSS OF DATA, FUNDS OR
                  LOSS OF DIGITAL ASSETS FOR FAILURE OF A PROJECT.
                </p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Terms;
