import * as actionTypes from '../types';

const initialState = {
  subscribeNewsletterError: null,
  subscribeNewsletterLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCESS_TOKEN:
      return {
        ...state,
        subscribeNewsletterLoading: true
      };

    case actionTypes.FETCH_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        subscribeNewsletterLoading: false
      };

    case actionTypes.FETCH_ACCESS_TOKEN_ERROR:
      return {
        ...state,
        subscribeNewsletterLoading: false,
        subscribeNewsletterError: action.errorMsg
      };

    default:
      return state;
  }
};
