export const USER_SELECT_CHAIN = 'USER_SELECT_CHAIN';

export const USER_DEVICE_INFO = 'USER_DEVICE_INFO';
export const USER_DEVICE_INFO_SUCCESS = 'USER_DEVICE_INFO_SUCCESS';
export const USER_DEVICE_INFO_ERROR = 'USER_DEVICE_INFO_ERROR';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_ERROR_RESET = 'USER_LOGIN_ERROR_RESET';

export const USER_RESEND_VERIFY = 'USER_RESEND_VERIFY';
export const USER_RESEND_VERIFY_SUCCESS = 'USER_RESEND_VERIFY_SUCCESS';
export const USER_RESEND_VERIFY_ERROR = 'USER_RESEND_VERIFY_ERROR';

export const USER_TFA_ENABLED = 'USER_TFA_ENABLED';
export const USER_TFA_LOGIN = 'USER_TFA_LOGIN';
export const USER_TFA_LOGIN_SUCCESS = 'USER_TFA_LOGIN_SUCCESS';
export const USER_TFA_LOGIN_ERROR = 'USER_TFA_LOGIN_ERROR';

export const USER_TFA_DENY = 'USER_TFA_DENY';
export const USER_TFA_DENY_SUCCESS = 'USER_TFA_DENY_SUCCESS';
export const USER_TFA_DENY_ERROR = 'USER_TFA_LOGIN_ERROR';

export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_ERROR = 'USER_FORGOT_PASSWORD_ERROR';

export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR';

export const FETCH_USER_REFERRAL = 'FETCH_USER_REFERRAL';
export const FETCH_USER_REFERRAL_SUCCESS = 'FETCH_USER_REFERRAL_SUCCESS';
export const FETCH_USER_REFERRAL_ERROR = 'FETCH_USER_REFERRAL_ERROR';

export const FETCH_USER_HISTORY = 'FETCH_USER_HISTORY';
export const FETCH_USER_HISTORY_SUCCESS = 'FETCH_USER_HISTORY_SUCCESS';
export const FETCH_USER_HISTORY_ERROR = 'FETCH_USER_HISTORY_ERROR';

export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_ERROR = 'USER_SIGNUP_ERROR';
export const USER_SIGNUP_ERROR_RESET = 'USER_SIGNUP_ERROR_RESET';

export const USER_SIGNOUT = 'USER_SIGNOUT';
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS';
export const USER_SIGNOUT_ERROR = 'USER_SIGNOUT_ERROR';

export const USER_WITHDRAW = 'USER_WITHDRAW';
export const USER_WITHDRAW_SUCCESS = 'USER_WITHDRAW_SUCCESS';
export const USER_WITHDRAW_ERROR = 'USER_WITHDRAW_ERROR';

export const USER_VERIFY_EMAIL = 'USER_VERIFY_EMAIL';
export const USER_VERIFY_EMAIL_SUCCESS = 'USER_VERIFY_EMAIL_SUCCESS';
export const USER_VERIFY_EMAIL_ERROR = 'USER_VERIFY_EMAIL_ERROR';

export const FETCH_MY_CONTRACTS = 'FETCH_MY_CONTRACTS';
export const FETCH_PUBLIC_CONTRACTS = 'FETCH_PUBLIC_CONTRACTS';
export const FETCH_MY_CONTRACTS_SUCCESS = 'FETCH_MY_CONTRACTS_SUCCESS';
export const FETCH_MY_CONTRACTS_ERROR = 'FETCH_MY_CONTRACTS_ERROR';

export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';

export const ADD_MY_CONTRACT = 'ADD_MY_CONTRACT';
export const ADD_MY_CONTRACT_SUCCESS = 'ADD_MY_CONTRACT_SUCCESS';
export const ADD_MY_CONTRACT_ERROR = 'ADD_MY_CONTRACT_ERROR';

export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_ERROR = 'UPDATE_CONTRACT_ERROR';

export const FETCH_CONTRACT = 'FETCH_CONTRACT';
export const FETCH_CONTRACT_SUCCESS = 'FETCH_CONTRACT_SUCCESS';
export const FETCH_CONTRACT_ERROR = 'FETCH_CONTRACT_ERROR';

export const FETCH_ACCESS_TOKEN = 'FETCH_ACCESS_TOKEN';
export const FETCH_ACCESS_TOKEN_SUCCESS = 'FETCH_ACCESS_TOKEN_SUCCESS';
export const FETCH_ACCESS_TOKEN_ERROR = 'FETCH_ACCESS_TOKEN_ERROR';

export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO';
export const UPDATE_ACCOUNT_INFO_SUCCESS = 'UPDATE_ACCOUNT_INFO_SUCCESS';
export const UPDATE_ACCOUNT_INFO_ERROR = 'UPDATE_ACCOUNT_INFO_ERROR';

export const UPDATE_WALLET_INFO = 'UPDATE_WALLET_INFO';
export const UPDATE_WALLET_INFO_SUCCESS = 'UPDATE_WALLET_INFO_SUCCESS';
export const UPDATE_WALLET_INFO_ERROR = 'UPDATE_WALLET_INFO_ERROR';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const DISABLE_TFA = 'DISABLE_TFA';
export const DISABLE_TFA_SUCCESS = 'DISABLE_TFA_SUCCESS';
export const DISABLE_TFA_ERROR = 'DISABLE_TFA_ERROR';

export const FETCH_2FA_QR = 'FETCH_2FA_QR';
export const FETCH_2FA_QR_SUCCESS = 'FETCH_2FA_QR_SUCCESS';
export const FETCH_2FA_QR_ERROR = 'FETCH_2FA_QR_ERROR';

export const ENABLE_TFA = 'ENABLE_TFA';
export const ENABLE_TFA_SUCCESS = 'ENABLE_TFA_SUCCESS';
export const ENABLE_TFA_ERROR = 'ENABLE_TFA_ERROR';

export const VERIFY_TFA = 'VERIFY_TFA';
export const VERIFY_TFA_SUCCESS = 'VERIFY_TFA_SUCCESS';
export const VERIFY_TFA_ERROR = 'VERIFY_TFA_ERROR';

export const FETCH_CRYPTO_PRICE = 'FETCH_CRYPTO_PRICE';
export const FETCH_CRYPTO_PRICE_SUCCESS = 'FETCH_CRYPTO_PRICE_SUCCESS';
export const FETCH_CRYPTO_PRICE_ERROR = 'FETCH_CRYPTO_PRICE_ERROR';

export const SUBSCRIBE_NEWSLETTER = 'SUBSCRIBE_NEWSLETTER';
export const SUBSCRIBE_NEWSLETTER_SUCCESS = 'SUBSCRIBE_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_NEWSLETTER_ERROR = 'SUBSCRIBE_NEWSLETTER_ERROR';

export const UPDATE_INVESTOR_DATA = 'UPDATE_INVESTOR_DATA';
export const UPDATE_INVESTOR_DATA_SUCCESS = 'UPDATE_INVESTOR_DATA_SUCCESS';
export const UPDATE_INVESTOR_DATA_ERROR = 'UPDATE_INVESTOR_DATA_ERROR';

export const REGISTER_BRO = 'REGISTER_BRO';
export const REGISTER_BRO_SUCCESS = 'REGISTER_BRO_SUCCESS';
export const REGISTER_BRO_ERROR = 'REGISTER_BRO_ERROR';

export const FETCH_BRO_WHITELIST = 'FETCH_BRO_WHITELIST';
export const FETCH_BRO_WHITELIST_SUCCESS = 'FETCH_BRO_WHITELIST_SUCCESS';
export const FETCH_BRO_WHITELIST_ERROR = 'FETCH_BRO_WHITELIST_ERROR';

export const FETCH_BRO_TOTAL = 'FETCH_BRO_TOTAL';
export const FETCH_BRO_TOTAL_SUCCESS = 'FETCH_BRO_TOTAL_SUCCESS';
export const FETCH_BRO_TOTAL_ERROR = 'FETCH_BRO_TOTAL_ERROR';

export const PROGRESS_MODAL_TOGGLE = 'PROGRESS_MODAL_TOGGLE';
export const PROGRESS_UPDATE_STATUS = 'PROGRESS_UPDATE_STATUS';
