import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import Web3 from 'web3';
// import PropTypes from 'prop-types';
// import { withTheme, createMuiTheme } from '@material-ui/core/styles';
import withRoot from './withRoot';
import Routes from '../../Routes';
import Header from '../../components/Header';
import InternalHeader from '../../components/Header/internalHeader';
import PreLoader from '../../components/PreLoader';
import Modals from '../Modals';
import Loader from '../../components/Loader';
import authActions from '../../redux/actions/auth';
import ErrorBoundary from '../../ErrorBoundary';
import { icoJson } from '../../constant/artifacts';
import { browserName, deviceType } from 'react-device-detect';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      loadingError: null,
      modal: null
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('tozex_jwtToken');
    if (!this.props.isAuthenticated && token) {
      this.props
        .fetchCurrentUser(token)
        .then(() => {
          this.setState({
            isLoaded: true
          });
        })
        .catch(e => {
          this.setState({
            isLoaded: true,
            loadingError: e
          });
        });
    } else if (!this.state.isLoaded) {
      this.setState({
        isLoaded: true
      });
    }
    let deviceInfo = {
      browser: browserName,
      device: deviceType ? deviceType : 'desktop'
    };
    this.props.getDeviceInfo(deviceInfo);
  }

  toggleModal = (modal = null) => {
    this.props.loginErrorReset();
    this.props.signUpErrorReset();

    this.setState({ modal });
  };

  render() {
    const { isLoaded, modal } = this.state;

    const childProps = {
      isAuthenticated: this.props.isAuthenticated,
      modal,
      toggleModal: this.toggleModal,
      path: this.props.location.pathname
    };

    const isHeaderAvailable = !childProps.path.includes('auth');

    return isLoaded ? (
      <ErrorBoundary>
        <PreLoader />

        {isHeaderAvailable && <Header {...childProps} />}

        <Routes childProps={childProps} />

        {modal && <Modals {...childProps} />}
      </ErrorBoundary>
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.userId !== null
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCurrentUser: token => authActions.fetchCurrentUser(token),
      loginErrorReset: () => authActions.loginErrorReset(),
      signUpErrorReset: () => authActions.signUpErrorReset(),
      getDeviceInfo: data => authActions.getDeviceInfo(data)
    },
    dispatch
  );

export default withRoot(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(App)
  )
);
