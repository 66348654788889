const types = require('../types');

const INITIAL_STATE = {
  isFetching: false,
  prices: [],
  msg: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CRYPTO_PRICE:
      return {
        ...state,
        isFetching: true,
        msg: ''
      };

    case types.FETCH_CRYPTO_PRICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        prices: action.payload.prices,
        msg: action.payload.msg
      };

    default:
      return state;
  }
};
