import * as actionTypes from '../types';

const initialState = {
  transactions: [],
  fetchTransactionsError: null,
  fetchTransactionsLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSACTIONS:
      return {
        ...state,
        transactionsLoading: true
      };

    case actionTypes.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionsLoading: false,
        transactions: action.data.transactions || []
      };

    case actionTypes.FETCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        transactionsLoading: false,
        fetchTransactionsError: action.errorMsg
      };

    default:
      return state;
  }
};
