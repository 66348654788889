import React, { Component } from 'react';
import { renderSvg } from '../../constant/helpers';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import authActions from '../../redux/actions/auth';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 1,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 2420,
      settings: {
        slidesToShow: 6
      }
    },
    {
      breakpoint: 2090,
      settings: {
        slidesToShow: 5
      }
    },
    {
      breakpoint: 1756,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 1425,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 1094,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: '00',
      minutes: '00',
      hours: '01',
      days: '00'
    };
  }

  componentDidMount() {
    setInterval(this.timer, 1000);
  }

  getTotalAmountPercentage() {
    const { totalAmount } = this.props;

    if (!totalAmount) {
      return 0;
    } else {
      const percent = (this.props.totalAmount * 100) / 3000000;
      return percent.toFixed(2);
    }
  }

  timer = () => {
    const countDownDate = new Date('June 22, 2020 15:00:00').getTime();
    // Get todays date and time
    var now = new Date().getTime();
    // Find the distance between now an the count down date
    var distance = countDownDate - now;
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (seconds < 10) {
      this.setState({
        seconds: '0' + seconds
      });
    } else {
      this.setState({
        seconds: seconds
      });
    }

    if (minutes < 10) {
      this.setState({
        minutes: '0' + minutes
      });
    } else {
      this.setState({
        minutes: minutes
      });
    }

    if (hours < 10) {
      this.setState({
        hours: '0' + hours
      });
    } else {
      this.setState({
        hours: hours
      });
    }

    if (days < 10) {
      this.setState({
        days: '0' + days
      });
    } else {
      this.setState({
        days: days
      });
    }
  };

  render() {
    const { days, hours, minutes, seconds } = this.state;

    return (
      <section id="Home-dark" className="home_dark valign-center align-center">
        <div
          className="parallax-bg"
          id="scene1"
          style={{
            transform: 'translate3d(0px, 0px, 0px) rotate(0.0001deg)',
            transformStyle: 'preserve-3d',
            backfaceVisibility: 'hidden',
            pointerEvents: 'none'
          }}
        >
          <img
            className="layer layer-3"
            data-depth="0.5"
            src={'/img/dark-skin/header/light-3.png'}
            alt=""
            style={{
              transform: 'translate3d(27.3px, -14.8px, 0px)',
              transformStyle: 'preserve-3d',
              backfaceVisibility: 'hidden',
              position: 'absolute',
              display: 'block',
              left: '0px',
              top: '0px'
            }}
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1">
              {renderSvg('tz-header__logo__icon-big', 'logo')}
            </div>

            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1 mt-5">
              <h3>CryptoAsset Platform for</h3>
              <div className="title title-center">
                <h2>Issuing, Financing &amp; Trading </h2>
              </div>
            </div>

            {/*<div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2 mt-5">*/}
            {/*  <a*/}
            {/*    href="https://drive.google.com/file/d/1m_LdMgz5V654BKv6buBYJOJCEJgNwmLy/view?usp=sharing"*/}
            {/*    target="_blank"*/}
            {/*    className="btn btn_medium light_button mr-3"*/}
            {/*  >*/}
            {/*    Whitepaper V1.4*/}
            {/*  </a>*/}
            {/*</div>*/}

            {/*<div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2 mt-5">*/}
            {/*  <a href="/bro-dashboard" className="btn btn_medium light_button btn-bro-pre-registration mr-3">*/}
            {/*    Invest in BRO*/}
            {/*    <span> Lend your stablecoins (TUSD/DAI/USDT) and simulate your quarterly interest !</span>*/}
            {/*  </a>*/}
            {/*</div>*/}

            {/*<div className="col-md-12 d-flex flex-column align-items-center justify-content-between mt-5">*/}
            {/*  <div className="d-flex timer-title">BRO CROWDLENDING START</div>*/}
            {/*</div>*/}

            {/*<div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2 my-2">*/}
            {/*  <div*/}
            {/*    className="counter wow fadeInUp mt-lg-3"*/}
            {/*    data-wow-delay="0.2s"*/}
            {/*    id="counter"*/}
            {/*    style={{ visibility: 'visible', animationDelay: '0.2s', animationName: '' }}*/}
            {/*  >*/}
            {/*    <ul>*/}
            {/*      <li className="days">*/}
            {/*        <p>Days</p>*/}
            {/*        <span>{days}</span>*/}
            {/*      </li>*/}
            {/*      <li className="hours">*/}
            {/*        <p>Hours</p>*/}
            {/*        <span>{hours}</span>*/}
            {/*      </li>*/}
            {/*      <li className="minutes">*/}
            {/*        <p>Minutes</p>*/}
            {/*        <span>{minutes}</span>*/}
            {/*      </li>*/}
            {/*      <li className="seconds">*/}
            {/*        <p>Seconds</p>*/}
            {/*        <span>{seconds}</span>*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          {/*<div className="d-flex flex-column align-items-center justify-content-between my-4 token-progress">*/}
          {/*  <div className="d-flex align-items-center">*/}
          {/*    <div className="token-value">73%</div>*/}
          {/*    <div className="total-invest">*/}
          {/*      <p className="total-invest-title">Total raised</p>*/}
          {/*      <p className="total-invest-value">25000 TUSD</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="col-md-12 d-flex flex-column align-items-center justify-content-between my-5">*/}
          {/*  <div className="d-flex timer-title">BRO INVESTMENT- TOZ/$</div>*/}
          {/*</div>*/}

          {/*<div className="progress progress-sm mb-xs">*/}
          {/*  <div*/}
          {/*    className="progress-bar"*/}
          {/*    role="progressbar"*/}
          {/*    aria-valuemin="0"*/}
          {/*    aria-valuemax="100"*/}
          {/*    aria-valuenow="73"*/}
          {/*    style={{ width: `${this.getTotalAmountPercentage()}%` }}*/}
          {/*  >*/}
          {/*    73*/}
          {/*  </div>*/}

          {/*  <div className="progress-label">*/}
          {/*    {this.getTotalAmountPercentage()}% - {this.props.totalAmount}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ bro }) => ({
  totalCount: bro.totalCount,
  totalAmount: bro.totalAmount
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Banner);
