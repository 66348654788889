import axios from 'axios';
import errorHandler from '../../helpers/exceptions';

import * as actionTypes from '../types';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URI
});

const newsletterActions = {
  subscribeNewsletter: function(data) {
    return dispatch => {
      dispatch(this.subscribeNewsletterStart());

      return instance
        .post('newsletter', data)
        .then(response => {
          dispatch(this.subscribeNewsletterSuccess(response.data));
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.subscribeNewsletterError(message));
          return Promise.reject({ message });
        });
    };
  },

  subscribeNewsletterStart: function() {
    return {
      type: actionTypes.SUBSCRIBE_NEWSLETTER
    };
  },

  subscribeNewsletterSuccess: function(data) {
    return {
      type: actionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS,
      data
    };
  },

  subscribeNewsletterError: function(errorMsg) {
    return {
      type: actionTypes.SUBSCRIBE_NEWSLETTER_ERROR,
      errorMsg
    };
  }
};

export default newsletterActions;
