import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';

import authActions from '../../redux/actions/auth';
import { renderSvg } from '../../constant/helpers';
import Marquee from './marqee';

const $ = window.$;

const styles = {
  purpleAvatar: {
    width: 32,
    height: 32,
    color: '#fff',
    backgroundColor: deepPurple[500]
  }
};

class Header extends Component {
  state = {
    menuOpened: false,
    dropdownOpen: false,
    scrollTop: true,
    menuItemExpanded: true,
    aboutItemExpanded: false
  };

  componentDidMount() {
    // const { isAuthenticated, path } = this.props;
    // if (!isAuthenticated && path !== '/') {
    //   this.props.redirectToHome();
    // }

    window.onscroll = this.onScroll;
  }

  onScroll = e => {
    if ($(e.target).scrollTop() > 0) {
      this.setState({
        scrollTop: false
      });
    } else {
      this.setState({
        scrollTop: true
      });
    }
  };

  getAvatarString = () => {
    return (
      (this.props.firstName ? this.props.firstName.charAt(0).toUpperCase() : '') +
      (this.props.lastName ? this.props.lastName.charAt(0).toUpperCase() : '')
    );
  };

  toggleMenu = () => {
    this.setState({
      menuOpened: !this.state.menuOpened
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  signOut = e => {
    e.preventDefault();

    this.props.signOut(this.props.token).then(() => {
      this.props.redirectToHome();
    });
  };

  onMenuItemExpand = () => {
    this.setState({
      menuItemExpanded: !this.state.menuItemExpanded,
      aboutItemExpanded: this.state.menuItemExpanded && this.state.aboutItemExpanded
    });
  };

  onAboutItemExpand = () => {
    this.setState({
      aboutItemExpanded: !this.state.aboutItemExpanded,
      menuItemExpanded: this.state.menuItemExpanded && this.state.aboutItemExpanded
    });
  };

  renderExternalHeader = () => {
    const { menuOpened, scrollTop } = this.state;
    const { path, isAuthenticated, prices } = this.props;

    return (
      <div>
        {/*<div className={scrollTop && prices.length > 0 ? 'marquee-header' : 'marquee-header-scrolling'}>*/}
        {/*  <Marquee>*/}
        {/*    {prices.map((item, index) => (*/}
        {/*      <Fragment key={index}>*/}
        {/*        <span className="marquee-header__symbol">*/}
        {/*          <a href="https://coinlib.io/coin/BTC/Bitcoin" target="_blank">*/}
        {/*            {item.name}*/}
        {/*            <span className="small"> [{item.symbol}]</span>*/}
        {/*          </a>*/}
        {/*        </span>*/}
        {/*        <span className="marquee-header__price">${item.price.toFixed(2)}</span>*/}
        {/*        <span className={`marquee-header__variance-${item.percentageChange24h > 0 ? 'plus' : 'minus'}`}>*/}
        {/*          {item.percentageChange24h > 0 ? '+' : ''}*/}
        {/*          {item.percentageChange24h.toFixed(2)}%*/}
        {/*        </span>*/}
        {/*      </Fragment>*/}
        {/*    ))}*/}
        {/*  </Marquee>*/}
        {/*</div>*/}

        <nav className={scrollTop ? '' : 'fixed-nav'}>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <a href="/" className="logotype">
                  {renderSvg('tz-header__logo__icon', 'logo')}
                </a>

                <span className="menu-button" onClick={() => this.toggleMenu()}>
                  <span />
                  <span />
                  <span />
                </span>

                {isAuthenticated && (
                  <a href="#" className="btn btn_small gradient_button" onClick={e => this.signOut(e)}>
                    Logout
                  </a>
                )}

                {!isAuthenticated && (
                  <a href="/auth/signup" className="btn btn_small gradient_button">
                    Sign Up
                  </a>
                )}

                {!isAuthenticated && (
                  <a href="/auth/login" className="btn btn_small light_button mr-3">
                    Login
                  </a>
                )}

                <ul className="site_menu">
                  <li>
                    <a href="#Tozex" className="nav-link active" data-toggle="dropdown">
                      Project
                    </a>
                    <ul className="navbar-nav">
                      <li>
                        <a className="dropdown-item" href="#Tozex">
                          What is Tozex?
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#Multiplatform">
                          Multiplatform
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#Users">
                          Users
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#Marketing">
                          Market Positioning
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#BRO">
                          Believers Reward Offering
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#Funding">
                          Funding structure
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href="#Team" className="nav-link" data-toggle="dropdown">
                      About
                    </a>
                    <ul className="navbar-nav">
                      <li>
                        <a className="dropdown-item" href="#Faq">
                          FAQ
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#Partners">
                          Partners
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="https://medium.com/tozex" target="_blank">
                          Blog
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#Subscribe">
                          Newsletter
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href="/bro-dashboard" className="">
                      InvestPad
                    </a>
                  </li>
                  <li>
                    <a href="/tozex-launch" className="">
                      TokenPad
                    </a>
                  </li>
                  <li>
                    <a href="/exchange" className="">
                      Trading
                    </a>
                  </li>
                  <li>
                    <a href="https://tozexbridge.web.app" className="">
                      Tozex Swap Bridge
                    </a>
                  </li>
                  {isAuthenticated && (
                    <li>
                      <a href="/myaccount" className="">
                        My Profile
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  };

  renderInternalHeader = () => {
    const { menuOpened, scrollTop } = this.state;
    const { classes, tozBalance } = this.props;

    return (
      <nav className={scrollTop ? '' : 'fixed-nav'}>
        <div className="px-4">
          <div className="row">
            <div className="col-xl-12">
              <a href="/" className="logotype">
                {renderSvg('tz-header__logo__icon', 'logo')}
              </a>
              <div className="d-flex justify-flex-end">
                {/*<div className="user-status-balance-float">*/}
                {/*{tozBalance}*/}
                {/*<small>TOZ</small>*/}
                {/*</div>*/}

                <span className="menu-button float-right" onClick={() => this.toggleMenu()}>
                  <span />
                  <span />
                  <span />
                </span>

                {/*<a href="#" className="btn btn_small gradient_button" onClick={e => this.signOut(e)}>*/}
                {/*Logout*/}
                {/*</a>*/}

                <ul className="site_menu">
                  <li style={{ marginTop: '4px', marginLeft: '22px' }}>
                    <a href="/bro-dashboard" className="">
                      InvestPad
                    </a>
                  </li>
                  <li style={{ marginTop: '4px' }}>
                    <a href="/tozex-launch" className="">
                      TokenPad
                    </a>
                  </li>
                  <li style={{ marginTop: '4px' }}>
                    <a href="/exchange" className="">
                      Trading
                    </a>
                  </li>

                  <li>
                    <a href="https://tozexbridge.web.app" className="">
                      Tozex Swap Bridge
                    </a>
                  </li>

                  <li>
                    <a
                      className={`toggle-tigger user-thumb ${this.state.dropdownOpen ? 'active' : ''}`}
                      href="javascript:;"
                      style={{ textDecoration: 'none' }}
                      onClick={this.toggleDropdown}
                    >
                      <Avatar className={classes.purpleAvatar}>{this.getAvatarString()}</Avatar>
                    </a>
                    <div
                      className={`toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown ${
                        this.state.dropdownOpen ? 'active' : ''
                      }`}
                    >
                      <div className="user-status">
                        <h6 className="user-status-title">Token balance</h6>
                        <div className="user-status-balance">
                          {tozBalance} <small>TOZ</small>
                        </div>
                      </div>
                      <ul className="user-links" style={{ float: 'none' }}>
                        <li>
                          <a href="/myaccount">
                            <i className="ti ti-id-badge" />
                            My Profile
                          </a>
                        </li>
                        {/*<li><a href="#"><i className="ti ti-infinite"></i>Referral</a></li>*/}
                        {/*<li><a href="activity.html"><i className="ti ti-eye"></i>Activity</a></li>*/}
                      </ul>
                      <ul className="user-links bg-light">
                        <li>
                          <a href="javascript:;" onClick={e => this.signOut(e)}>
                            <i className="ti ti-power-off" />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  };

  render() {
    const { menuOpened, scrollTop, menuItemExpanded, aboutItemExpanded } = this.state;
    const { path, isAuthenticated } = this.props;

    let headerClass = '';
    if (path == '/') {
      headerClass = 'site_header site_header_dark';
    } else {
      headerClass = 'site_header site_header_internal';
    }

    return (
      <header className={headerClass}>
        {path == '/' || path.includes('blogs') ? this.renderExternalHeader() : this.renderInternalHeader()}

        <div className={menuOpened ? 'mobile-menu active-menu' : 'mobile-menu'}>
          <span className="close-menu-button" onClick={() => this.toggleMenu()}>
            <span />
            <span />
          </span>

          <a href="#" className="logotype">
            {renderSvg('tz-header__logo__icon', 'logo')}
          </a>
          <ul>
            <li className={this.state.menuItemExpanded ? 'active' : ''}>
              <a
                href="#Tozex"
                className={'dropdown-toggle ' + (menuItemExpanded ? '' : 'collapsed')}
                data-toggle="collapse"
                aria-expanded={menuItemExpanded ? 'true' : 'false'}
                onClick={this.onMenuItemExpand}
              >
                Project
              </a>
              <ul className={'collapse list-unstyled ' + (menuItemExpanded ? 'show' : '')}>
                <li className="">
                  <a className="dropdown-item" href="#Tozex">
                    What is Tozex?
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#Multiplatform">
                    Multiplatform
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#Users">
                    Users
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#Marketing">
                    Market Positioning
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#BRO">
                    Believers Reward Offering
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#Funding">
                    Funding structure
                  </a>
                </li>
              </ul>
            </li>
            <li className={this.state.aboutItemExpanded ? 'active' : ''}>
              <a
                href="#Team"
                className={'dropdown-toggle ' + (aboutItemExpanded ? '' : 'collapsed')}
                data-toggle="collapse"
                aria-expanded={aboutItemExpanded ? 'true' : 'false'}
                onClick={this.onAboutItemExpand}
              >
                About
              </a>
              <ul className={'collapse list-unstyled ' + (aboutItemExpanded ? 'show' : '')}>
                <li>
                  <a className="dropdown-item" href="#Team">
                    Team
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#Faq">
                    FAQ
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#Partners">
                    Partners
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="https://medium.com/tozex" target="_blank">
                    Blog
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#Subscribe">
                    Newsletter
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/exchange" className="">
                Trading
              </a>
            </li>
            <li>
              <a href="/bro-dashboard" className="">
                InvestPad
              </a>
            </li>
            <li>
              <a href="/tozex-launch" className="">
                TokenPad
              </a>
            </li>
            {isAuthenticated && (
              <li>
                <a href="/myaccount" className="">
                  My Profile
                </a>
              </li>
            )}
          </ul>
          <div className="menu-footer">
            {isAuthenticated ? (
              <a href="#" className="btn btn_small btn-block gradient_button" onClick={e => this.signOut(e)}>
                Logout
              </a>
            ) : (
              <div className="">
                <a href="/auth/signup" className="btn btn_small btn-block gradient_button">
                  Sign Up
                </a>
                <a href="/auth/login" className="btn btn_small btn-block outline_button">
                  Login
                </a>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({ auth, prices }) => ({
  token: auth.userToken,
  name: `${auth.userData.firstName} ${auth.userData.lastName}`,
  firstName: auth.userData.firstName,
  lastName: auth.userData.lastName,
  tozBalance: auth.userData.tozBalance,
  prices: prices.prices
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirectToHome: () => push('/'),
      signOut: token => authActions.signOut(token)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
