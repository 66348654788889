import axios from 'axios';
import errorHandler from '../../helpers/exceptions';

import * as actionTypes from '../types';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URI
});

const axiosConfig = token => ({
  headers: {
    ...(token && { Authorization: 'Bearer ' + token })
  }
});

const authActions = {
  setChain: function(chain) {
    return {
      type: actionTypes.USER_SELECT_CHAIN,
      payload: chain
    };
  },

  getDeviceInfo: function(data) {
    return dispatch => {
      dispatch(this.getDeviceInfoStart());

      return dispatch(this.getDeviceInfoSuccess(data));
    };
  },

  getDeviceInfoStart: function() {
    return {
      type: actionTypes.USER_DEVICE_INFO
    };
  },

  getDeviceInfoSuccess: function(data) {
    return {
      type: actionTypes.USER_DEVICE_INFO_SUCCESS,
      data
    };
  },

  login: function(data) {
    return dispatch => {
      dispatch(this.loginStart());

      return instance
        .post('/login', data)
        .then(response => {
          if (response.data.message && response.data.message === 'TFA Enabled') {
            // TFA enabled
            dispatch(
              this.tfaEnabled({
                token: response.data.token,
                qr: response.data.qr || ''
              })
            );

            return Promise.resolve('QR');
          } else {
            localStorage.setItem('tozex_jwtToken', response.data.token);
            dispatch(this.loginSuccess(response.data));
            return Promise.resolve(null);
          }
        })
        .catch(error => {
          errorHandler(error);
          const status = error.response.status;
          const message = error.response.data.error.msg || error.message;
          dispatch(this.loginError(status, message));
          return Promise.reject({ message });
        });
    };
  },

  loginStart: function() {
    return {
      type: actionTypes.USER_LOGIN
    };
  },

  loginSuccess: function(data) {
    return {
      type: actionTypes.USER_LOGIN_SUCCESS,
      data
    };
  },

  loginError: function(status, errorMsg) {
    return {
      type: actionTypes.USER_LOGIN_ERROR,
      status,
      errorMsg
    };
  },

  loginErrorReset: function() {
    return {
      type: actionTypes.USER_LOGIN_ERROR_RESET
    };
  },

  resendVerifyEmail: function(data) {
    return dispatch => {
      dispatch(this.resendVerifyEmailStart());

      return instance
        .post('/resend', data)
        .then(response => {
          dispatch(this.resendVerifyEmailSuccess());
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.resendVerifyEmailError(message));
          return Promise.reject({ message });
        });
    };
  },

  resendVerifyEmailStart: function() {
    return {
      type: actionTypes.USER_RESEND_VERIFY
    };
  },

  resendVerifyEmailSuccess: function(data) {
    return {
      type: actionTypes.USER_RESEND_VERIFY_SUCCESS,
      data
    };
  },

  resendVerifyEmailError: function(errorMsg) {
    return {
      type: actionTypes.USER_RESEND_VERIFY_ERROR,
      errorMsg
    };
  },

  tfaEnabled: function(data) {
    return {
      type: actionTypes.USER_TFA_ENABLED,
      data
    };
  },

  tfaLogin: function({ token, code }) {
    return dispatch => {
      dispatch(this.tfaLoginStart());

      return instance
        .post('/login/tfa', { code }, axiosConfig(token))
        .then(response => {
          localStorage.setItem('tozex_jwtToken', response.data.token);
          dispatch(this.tfaLoginSuccess(response.data));
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.tfaLoginError(message));
          return Promise.reject({ message });
        });
    };
  },

  tfaLoginStart: function() {
    return {
      type: actionTypes.USER_TFA_LOGIN
    };
  },

  tfaLoginSuccess: function(data) {
    return {
      type: actionTypes.USER_TFA_LOGIN_SUCCESS,
      data
    };
  },

  tfaLoginError: function(errorMsg) {
    return {
      type: actionTypes.USER_TFA_LOGIN_ERROR,
      errorMsg
    };
  },

  denyTfa: function(token) {
    return dispatch => {
      dispatch(this.denyTfaStart());

      return instance
        .post('/deny/tfa', {}, axiosConfig(token))
        .then(response => {
          dispatch(this.denyTfaSuccess(response.data));
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.denyTfaError(message));
          return Promise.reject({ message });
        });
    };
  },

  denyTfaStart: function() {
    return {
      type: actionTypes.USER_TFA_DENY
    };
  },

  denyTfaSuccess: function(data) {
    return {
      type: actionTypes.USER_TFA_DENY_SUCCESS,
      data
    };
  },

  denyTfaError: function(errorMsg) {
    return {
      type: actionTypes.USER_TFA_DENY_ERROR,
      errorMsg
    };
  },

  signUp: function(data) {
    return dispatch => {
      dispatch(this.signUpStart());

      return instance
        .post('signup', data)
        .then(response => {
          dispatch(this.signUpSuccess());
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.signUpError(message));
          return Promise.reject({ message });
        });
    };
  },

  signUpStart: function() {
    return {
      type: actionTypes.USER_SIGNUP
    };
  },

  signUpSuccess: function() {
    return {
      type: actionTypes.USER_SIGNUP_SUCCESS
    };
  },

  signUpError: function(errorMsg) {
    return {
      type: actionTypes.USER_SIGNUP_ERROR,
      errorMsg
    };
  },

  signUpErrorReset: function() {
    return {
      type: actionTypes.USER_SIGNUP_ERROR_RESET
    };
  },

  verifyEmail: function(data) {
    return dispatch => {
      dispatch(this.verifyEmailStart());

      return instance
        .post('confirmation', data)
        .then(response => {
          if (response.data.message && response.data.message === 'TFA Enabled') {
            // TFA enabled
            dispatch(
              this.tfaEnabled({
                token: response.data.token,
                qr: response.data.qr || ''
              })
            );
            return Promise.resolve('QR');
          } else {
            localStorage.setItem('tozex_jwtToken', response.data.token);
            dispatch(this.loginSuccess(response.data));
            return Promise.resolve(null);
          }
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.verifyEmailError(message));
          return Promise.reject({ message });
        });
    };
  },

  verifyEmailStart: function() {
    return {
      type: actionTypes.USER_VERIFY_EMAIL
    };
  },

  verifyEmailSuccess: function() {
    return {
      type: actionTypes.USER_VERIFY_EMAIL_SUCCESS
    };
  },

  verifyEmailError: function(errorMsg) {
    return {
      type: actionTypes.USER_VERIFY_EMAIL_ERROR,
      errorMsg
    };
  },

  fetchCurrentUser: function(token) {
    return dispatch => {
      dispatch(this.fetchCurrentUserStart());

      return instance
        .get('account/profile', axiosConfig(token))
        .then(response => {
          dispatch(this.fetchCurrentUserSuccess(response.data));
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          localStorage.removeItem('tozex_jwtToken');
          const message = error.response.data.error.msg || error.message;
          dispatch(this.fetchCurrentUserError(message));
          return Promise.reject({ message });
        });
    };
  },

  fetchCurrentUserStart: function() {
    return {
      type: actionTypes.FETCH_CURRENT_USER
    };
  },

  fetchCurrentUserSuccess: function(data) {
    return {
      type: actionTypes.FETCH_CURRENT_USER_SUCCESS,
      data
    };
  },

  fetchCurrentUserError: function(errorMsg) {
    return {
      type: actionTypes.FETCH_CURRENT_USER_ERROR,
      errorMsg
    };
  },

  fetchUserReferral: function() {
    return dispatch => {
      dispatch(this.fetchUserReferralStart());

      return instance
        .get('account/referral')
        .then(response => {
          dispatch(this.fetchUserReferralSuccess(response.data));
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.fetchUserReferralError(message));
          return Promise.reject({ message });
        });
    };
  },

  fetchUserReferralStart: function() {
    return {
      type: actionTypes.FETCH_USER_REFERRAL
    };
  },

  fetchUserReferralSuccess: function(data) {
    return {
      type: actionTypes.FETCH_USER_REFERRAL_SUCCESS,
      data
    };
  },

  fetchUserReferralError: function(errorMsg) {
    return {
      type: actionTypes.FETCH_USER_REFERRAL_ERROR,
      errorMsg
    };
  },

  fetchUserHistory: function() {
    return dispatch => {
      dispatch(this.fetchUserHistoryStart());

      return instance
        .get('account/history')
        .then(response => {
          dispatch(this.fetchUserHistorySuccess(response.data));
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.fetchUserHistoryError(message));
          return Promise.reject({ message });
        });
    };
  },

  fetchUserHistoryStart: function() {
    return {
      type: actionTypes.FETCH_USER_HISTORY
    };
  },

  fetchUserHistorySuccess: function(data) {
    return {
      type: actionTypes.FETCH_USER_HISTORY_SUCCESS,
      data
    };
  },

  fetchUserHistoryError: function(errorMsg) {
    return {
      type: actionTypes.FETCH_USER_HISTORY_ERROR,
      errorMsg
    };
  },

  signOut: function(token) {
    return dispatch => {
      dispatch(this.signOutStart());

      return instance
        .get('logout', axiosConfig(token))
        .then(() => dispatch(this.signOutSuccess()))
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.signOutError(message));
        })
        .then(() => {
          localStorage.removeItem('tozex_jwtToken');
        });
    };
  },

  signOutStart: function() {
    return {
      type: actionTypes.USER_SIGNOUT
    };
  },

  signOutSuccess: function() {
    return {
      type: actionTypes.USER_SIGNOUT_SUCCESS
    };
  },

  signOutError: function(errorMsg) {
    return {
      type: actionTypes.USER_SIGNOUT_ERROR,
      errorMsg
    };
  },

  updateInvestorData: function(data) {
    return dispatch => {
      dispatch(this.updateInvestorDataStart());

      return instance
        .post('account/investorData', data)
        .then(response => {
          dispatch(this.updateInvestorDataSuccess(response.data));
          return Promise.resolve();
        })
        .catch(error => {
          errorHandler(error);
          const message = error.response.data.error.msg || error.message;
          dispatch(this.updateInvestorDataError(message));
          return Promise.reject({ message });
        });
    };
  },

  updateInvestorDataStart: function() {
    return {
      type: actionTypes.FETCH_USER_HISTORY
    };
  },

  updateInvestorDataSuccess: function(data) {
    return {
      type: actionTypes.FETCH_USER_HISTORY_SUCCESS,
      data
    };
  },

  updateInvestorDataError: function(errorMsg) {
    return {
      type: actionTypes.FETCH_USER_HISTORY_ERROR,
      errorMsg
    };
  }
};

export default authActions;
