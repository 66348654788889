import * as actionTypes from '../types';

const initialState = {
  accessToken: null,
  fetchAccessTokenError: null,
  fetchAccessTokenLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCESS_TOKEN:
      return {
        ...state,
        fetchAccessTokenLoading: true
      };

    case actionTypes.FETCH_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        fetchAccessTokenLoading: false,
        accessToken: action.data.token
      };

    case actionTypes.FETCH_ACCESS_TOKEN_ERROR:
      return {
        ...state,
        fetchAccessTokenLoading: false,
        fetchAccessTokenError: action.errorMsg
      };

    default:
      return state;
  }
};
