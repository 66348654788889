import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import mycontracts from './mycontracts';
import kyc from './kyc';
import prices from './prices';
import payments from './payments';
import newsletter from './newsletter';
import bro from './bro';
import progress from './progress';
import account from './account';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    account,
    mycontracts,
    kyc,
    prices,
    payments,
    newsletter,
    bro,
    progress
  });
